import React, { useEffect } from "react";
import {
  FormControl,
  Checkbox as MUICheckbox,
  FormControlLabel
} from "@mui/material";

import { ICheckbox } from "../../../models/checkbox";

import "./Checkbox.scss";

const Checkbox = ({ onChange, label, className = "", value, disabled = false }: ICheckbox) => {
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setChecked(value);
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent) => {
    const { checked } = event.target as HTMLInputElement;

    setChecked(checked);

    onChange && onChange(checked);
  };

  return (
    <FormControl component="fieldset" className={`checkbox ${className}`}>
      {label && (
        <FormControlLabel
          control={<MUICheckbox color="primary" />}
          label={label}
          labelPlacement="end"
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
        />
      )}
    </FormControl>
  );
};

export default Checkbox;
