import { object, boolean, string, date } from "yup";
import { CONSTANTS } from "../constants";

export const fwVersionDetailsValidation = object().shape({
    title: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    fileName: string().typeError(CONSTANTS.VALIDATION_ERRORS.FILE).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    pemType: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    version: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    isActive: boolean().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
});

