import React, {
    useState,
    useEffect,
} from "react";
import { IOrder } from "../../../../store/entities/orders/type";
import DescendingHierarchyForm, { DescendingHierarchyFormEnum, IselectedData } from "../../../../components/Container/descendingHierarchyForm/descendingHierarchyForm";
import moment from "moment";
import { AllocateOrder } from "../../../../models/orders-management";
import { GeneralActions } from "../../../../store/entities/general/general.actions";
import { useDispatch } from "react-redux";
import useUrlSearchParam from "../../../../hooks/useUrlSearchParams";
import { OrdersActions } from "../../../../store/entities/orders/orders.actions";
import Alert from "../../../../components/UI/Alert/Alert";
import useTranslations from "../../../../hooks/useTranslations";

interface Iprops {
    order: IOrder,
    showDialog: Function;
}

const AllocateCell = (props: Iprops) => {
    const dispatch = useDispatch();
    const { params } = useUrlSearchParam();
    const [show, setShow] = useState<boolean>(false);
    const [errorMessage,setErrorMessage] = useState("");
    const { rcTranslate } = useTranslations();



    const saveLockerAllocate = async (selectedData: IselectedData) => {
        if (selectedData.cellId && props.order && props.order.cellId !== selectedData.cellId) {
            try {
                await dispatch(GeneralActions.postAllocateCell(props.order.id, selectedData.cellId));
                dispatch(OrdersActions.getOrders(params));
                props.showDialog(false);
            } catch (error: any) {
                const resErrorCode = error.message;
                setErrorMessage(error.message);
                setShow(true);
                setTimeout(()=>{
                    setShow(false);
                    setErrorMessage("");
                },5000)
                if (resErrorCode.includes(AllocateOrder.CELL_UNAVIABLE && selectedData.siteId)) {
                    const now = new Date();
                    const startDate = moment(now).isAfter(props?.order?.startDate) ? now.toISOString() : moment(props?.order?.startDate).toISOString();
                    const endDate = moment(props?.order?.endDate).toISOString()
                    dispatch(GeneralActions.getUnavailableCellIdsBetweenDatesForSite(selectedData.siteId!, startDate, endDate));
                }
            }
        }
    }

    return (
    <>

            <DescendingHierarchyForm submitForm={saveLockerAllocate} order={props.order} showDialog={props.showDialog} type={DescendingHierarchyFormEnum.ALLOCATE} />
            {show && (
                <Alert
                    message={rcTranslate(errorMessage)}
                    color="error"
                />
            )}
        </>

    )
}

export default AllocateCell;