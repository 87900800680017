import { IApiError } from "../../../models/api";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { TableActions } from "../table/table.actions";
import { GetLockersPayload, PostBlockCellParams } from "./type";

const { get, post, put } = ApiService;

enum LockersActionsEnum {
  GET_LOCKERS = "GET_LOCKERS",
  POST_BLOCK_CELL = "POST_BLOCK_CELL",
  RELEASE_BLOCK_CELL = "RELEASE_BLOCK_CELL",
  UNPAIR_LOCKS_FROM_CELLS = "UNPAIR_LOCKS_FROM_CELLS"
};

export const LockersActionsNames = {
  [LockersActionsEnum.GET_LOCKERS]: generateAsyncActionNames(
    LockersActionsEnum.GET_LOCKERS
  ),
  [LockersActionsEnum.POST_BLOCK_CELL]: generateAsyncActionNames(
    LockersActionsEnum.POST_BLOCK_CELL
  ),
  [LockersActionsEnum.RELEASE_BLOCK_CELL]: generateAsyncActionNames(
    LockersActionsEnum.RELEASE_BLOCK_CELL
  ),
  [LockersActionsEnum.UNPAIR_LOCKS_FROM_CELLS]: generateAsyncActionNames(
    LockersActionsEnum.UNPAIR_LOCKS_FROM_CELLS
  )
};

const Actions = {
  [LockersActionsEnum.GET_LOCKERS]: {
    START: () => ({
      type: LockersActionsNames.GET_LOCKERS.START
    }),
    FULFILLED: (data: GetLockersPayload) => ({
      type: LockersActionsNames.GET_LOCKERS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LockersActionsNames.GET_LOCKERS.REJECTED,
      payload: error
    }),
  },
  [LockersActionsEnum.RELEASE_BLOCK_CELL]: {
    START: () => ({
      type: LockersActionsNames.RELEASE_BLOCK_CELL.START
    }),
    FULFILLED: () => ({
      type: LockersActionsNames.RELEASE_BLOCK_CELL.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LockersActionsNames.RELEASE_BLOCK_CELL.REJECTED,
      payload: error
    }),
  },
  [LockersActionsEnum.POST_BLOCK_CELL]: {
    START: () => ({
      type: LockersActionsNames.POST_BLOCK_CELL.START
    }),
    FULFILLED: () => ({
      type: LockersActionsNames.POST_BLOCK_CELL.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LockersActionsNames.POST_BLOCK_CELL.REJECTED,
      payload: error
    }),
  },
  [LockersActionsEnum.UNPAIR_LOCKS_FROM_CELLS]: {
    START: () => ({
      type: LockersActionsNames.UNPAIR_LOCKS_FROM_CELLS.START
    }),
    FULFILLED: () => ({
      type: LockersActionsNames.UNPAIR_LOCKS_FROM_CELLS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LockersActionsNames.UNPAIR_LOCKS_FROM_CELLS.REJECTED,
      payload: error
    }),
  },
};

const getLockers =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
  ) => async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[LockersActionsEnum.GET_LOCKERS].START());
    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize
        })
      }
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort
      });
    }

    try {
      const { data }: any = await get("Cell/GetCells", requestData);
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      const lockers = data?.content?.cells;

      dispatch(Actions[LockersActionsEnum.GET_LOCKERS].FULFILLED(lockers));

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[LockersActionsEnum.GET_LOCKERS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const postBlockCells = (blockData: PostBlockCellParams) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[LockersActionsEnum.POST_BLOCK_CELL].START());
    try {
      const { data }: { data: ApiResponse } = await post(`Cell/BlockCells`, { ...blockData });

      if (!data.isSucceeded) {
        throw Error(data.errors?.[0].code);
      }

      dispatch(Actions[LockersActionsEnum.POST_BLOCK_CELL].FULFILLED());
      
      return data?.content;
    } catch (error: any) {
      dispatch(
        Actions[LockersActionsEnum.POST_BLOCK_CELL].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

  const postReleaseBlockCells = (blockData: PostBlockCellParams) => async (dispatch: AppDispatch) => {
    dispatch(Actions[LockersActionsEnum.RELEASE_BLOCK_CELL].START());
    try {
      const { data }: { data: ApiResponse } = await post(`Cell/ReleaseBlockCells`, { ...blockData });
      if (!data.isSucceeded) {
        throw Error(data.errors?.[0].code);
      }
      dispatch(Actions[LockersActionsEnum.RELEASE_BLOCK_CELL].FULFILLED());
      return data;
    } catch (error: any) {
      dispatch(
        Actions[LockersActionsEnum.RELEASE_BLOCK_CELL].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

  const putUnpairLocksFromCells = (blockData: PostBlockCellParams) => async (dispatch: AppDispatch) => {
    dispatch(Actions[LockersActionsEnum.UNPAIR_LOCKS_FROM_CELLS].START());
    try {
      const { data }: { data: ApiResponse } = await put(`Cell/UnpairLocksFromCells`, { ...blockData });
      if (!data.isSucceeded) {
        throw Error(data.errors?.[0].code);
      }
      dispatch(Actions[LockersActionsEnum.UNPAIR_LOCKS_FROM_CELLS].FULFILLED());
      return data;
    } catch (error: any) {
      dispatch(
        Actions[LockersActionsEnum.UNPAIR_LOCKS_FROM_CELLS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

export const LockersActions = {
  getLockers,
  postBlockCells,
  postReleaseBlockCells,
  putUnpairLocksFromCells,
};
