export const CONSTANTS = {
  ROUTES: {
    BASE: "backoffice",
    HOME: {
      BASE: "",
    },
    LOGIN: {
      BASE: "login",
    },
    FORGOT_PASSWORD: {
      BASE: "forgot-password",
    },
    ROLE_MANAGEMENT: {
      BASE: "role-management",
      CHILDREN: {
        SET_ROLE: "set-role",
      },
    },
    PRODUCTS: {
      BASE: "products",
    },
    CUSTOMERS: {
      BASE: "customers",
      CHILDREN: {
        CUSTOMER_LIST: "list",
        EDIT_CUSTOMER: "edit",
        MANAGING_REGISTRATION_QUESTIONS: "managing-registration-questions",
        CUSTOMER_DETAILS: "customer-details",
      },
    },
    USERS_MANAGEMENT: {
      BASE: "users-management",
      CHILDREN: {
        USERS_LIST: "list",
        UPDATE_USER_PERMISSIONS: "user-permissions",
        USER_DETAILS: "user-details",
      },
    },
    SITES_MANAGEMENT: {
      BASE: "sites-management",
      CHILDREN: {
        SITES_LIST: "list",
        SITE_DETAILS: "site-details",
        COMPOUNDS_LIST: "compounds-list",
        CLOSETS_MANAGEMENT: "closets-management",
        CLOSETS_DETAILS: "closet-details",
      },
    },
    LOCKS_MANAGEMENT: {
      BASE: "locks-management",
      CHILDREN: {
        LOCKS_LIST: "list",
        RELEASE_MIGRATION_LOCK: "release-migration-lock",
        ADD_LOCK: "add-lock",
      },
    },
    CONFIGURATION: {
      BASE: "configuration",
      CHILDREN: {
        LIST_MANAGEMENT: "list",
        CELLS_MANAGEMENT: "cells-formation",
        COLUMNS_MANAGEMENT: "columns",
        CITIES_MANAGEMENT: "cities",
        CLOSETS_MANAGEMENT: "closets-formation",
        FW_VERSIONS_MANAGEMENT: "fw-versions",
        CLOSET_DETAILS: "closet-details",
        CLOSETS_DISPLAY: "closet-display",
      },
    },
    ORDERS_MANAGEMENT: {
      BASE: "orders-management",
      CHILDREN: {
        ORDERS_LIST: "list",
        ORDER_HISTORY: "order-history",
        ORDER_MESSAGES_HISTORY: "order-messages-history"
      },
    },
    LOCKERS_MANAGEMENT: {
      BASE: "lockers-management",
      CHILDREN: {
        LOCKERS_LIST: "list",
      },
    },
    CHANGE_EXPIRED_PASSWORD: {
      BASE: "change-expired-password",
    },
    WAITING_LIST_MANAGEMENT: {
      BASE: "waiting-list-management",
      CHILDREN: {
        WAITING_LIST: "list",
      },
    },
    PRICING_PLANS_MANAGEMENT: {
      BASE: "pricing-plans-management",
      CHILDREN: {
        PRICING_PLANS_LIST: "list",
        PRICING_PLAN_DETAILS: "pricing-plan-details",
      },
    },
    REPORTS_MANAGEMENT: {
      BASE: "reports-management",
      CHILDREN: {
        ORDERS_REPORTS: "orders-reports",
        ORDERS_BY_SITES_REPORTS: "orders-by-sites-reports",
        SITE_DETAILS_REPORTS: "site-details-reports"
      },
    },
  },
  YES_NO_LIST: [
    { key: "", value: "common.all" },
    { key: 0, value: "common.no" },
    { key: 1, value: "common.yes" },
  ],
  DYNAMIC_LIST: {
    KEYS: {
      RENTAL_ROUTES: "RENTAL_ROUTES",
      DISTRICTS: "DISTRICTS",
      COMPOUND_NAMES: "COMPOUND_NAMES",
      ORDER_REGISTRATION_PLATFORMS: "ORDER_REGISTRATION_PLATFORMS",
      PAYMENT_METHODS: "PAYMENT_METHODS",
      ORDER_OPERATION_TYPES: "ORDER_OPERATION_TYPES",
      USER_TYPES: "USER_TYPES",
      COLORS: "COLORS",
      MANUFACTURERS: "MANUFACTURERS",
      DOOR_CLADDINGS: "DOOR_CLADDINGS",
      CELL_STATUSES: "CELL_STATUSES",
      LOCK_STATUSES: "LOCK_STATUSES",
      PEM_TYPES: "PEM_TYPES",
      ORDER_STATUSES: "ORDER_STATUSES",
      WAITING_LIST_STATUSES: "WAITING_LIST_STATUSES",
      WAITING_LIST_TYPES: "WAITING_LIST_TYPES",
      SUBSCRIPTION_MODELS: "SUBSCRIPTION_MODELS",
      CELL_SIZES: "CELL_SIZES",
      PAYMENT_STATUSES: "PAYMENT_STATUSES",
    },
  },
  GRIDS: {
    PAGE_SIZE: 50,
    PAGE_SIZE_LARGE: 300,
    FIRST_PAGE_NUMBER: 1,
  },
  REGEX_PATTERN_VALIDATORS: {
    PASSWORD_ACCOUNT: new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    ),
    HEB_ENG_NAME_NO_NUMBERS: new RegExp(/^[\u0590-\u05FF-a-zA-Z\s - _]*$/),
    ONLY_NUMBERS_REGEX: new RegExp(/^[0-9]+$/),
    ONLY_NUMBERS_AND_HYPHEN_REGEX: new RegExp(/^[\d\-]*$/),
    ONLY_DECIMAL_NUMBERS_REGEX: new RegExp(/^\d*\.?\d*$/),
    ONLY_NUMBERS_ENG_SPECIAL: new RegExp(/^[A-Za-z0-9!@#$%^&*]*$/),
    ONE_BIG_ONE_SMALL: new RegExp(/^(?=.*[a-z])(?=.*[A-Z](?!.*[^a-zA-Z0-9]))/),
    HEB_ENG_NAME_NUMBERS: new RegExp(/^[\u0590-\u05FF-a-zA-Z0-9\s - _]*$/),
    DATE: new RegExp(
      /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/
    ),
    DATE_OLD: new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/),
    HH_MM: new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
    ENTRANCE_REGEX: new RegExp(/^[\u0590-\u05FF-a-zA-Z0-9\s ./'",-@]*$/),
    HEB_ENG_NAME_NUMBERS_SPACE: new RegExp(/^[\u0590-\u05FF-a-zA-Z0-9\s]*$/),
    DATE_PICKER: new RegExp(
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
    ),
    NO_SPACES: new RegExp(/^\S+$/),
    LINK_WITH_ID: new RegExp(
      /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=)([\w-_]+)/
    ),
    PHONE_NUMBER: new RegExp(/^(05[0-9][0-9]{7})/),
    HEB_TEXT: new RegExp(/^[\u0590-\u05FF\s - _]*$/),
    ENG_TEXT: new RegExp(/^[a-zA-Z\s - _]*$/),
    EXTENSION_FILE_NAME: new RegExp(/filename\*=UTF-8\'\'(.*?)\.(pdf|xlsx)/),
  },
  VALIDATION_TYPES: {
    ID_NUMBER: "ID_NUMBER",
    EMAIL: "EMAIL",
    PASSWORD: "PASSWORD",
    NEW_PASSWORD: "NEW_PASSWORD",
    TEL: "TEL",
    NUMBERS: "NUMBERS",
    HEB_TEXT: "HEB_TEXT",
    ENG_TEXT: "ENG_TEXT",
  },
  API: {
    SORT_ASC: 0,
    SORT_DESC: 1,
  },
  DEFAULT_LANGUAGE: "hebrew",
  VALIDATION_ERRORS: {
    REQUIRED: "שדה חובה",
    NUMBERS: "יש להזין מספרים בלבד",
    FILE: "יש להעלות קובץ",
    DATE: "יש להזין תאריך תקין",
    PPPORPPPUREQUIRED: "חובה לבחור לפחות מחירון אחד לאתר",
  },
  Site_Details: {
    PPPID: "pppId",
    PPUID: "ppuId",
  },
  CELL_SIZES: {
    WIDTH_MULTIPLIER: 1.8,
    HEIGHT_MULTIPLIER: 2.5,
    UNKNOWN: {
      NAME: "UNKNOWN",
    },
    S: {
      NAME: "S",
    },
    M: {
      NAME: "M",
    },
    L: {
      NAME: "L",
    },
    XL: {
      NAME: "XL",
    },
    XXL: {
      NAME: "XXL",
    },
  },
  CHECKBOX: "checkbox",
  COLORS: {
   RED: "#ff0000",
   BLACK: "#000",
   GRAY: "#6C757D"
  }
};
