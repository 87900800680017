import { Navigate, Route, Routes } from "react-router-dom";

import { useAppSelector } from "../../../store";
import { CONSTANTS } from "../../../utils/constants";
import { IRouteConfig } from "../../../models/route";
import GuardedRoute from "../GuardedRoute/GuardedRoute";
import { PERMISSIONS } from "../../../utils/permissions";
import CustomerList from "../../../pages/Customers/CustomerList/CustomerList";
import CustomerDetails from "../../../pages/Customers/CustomerDetails/CustomerDetails";
import ManageRoles from "../../../pages/Roles/ManageRoles/ManageRoles";
import SetRole from "../../../pages/Roles/SetRole/SetRole";
import ManageUsers from "../../../pages/Users/ManageUsers/ManageUsers";
import UpdateUserPermissions from "../../../pages/Users/UpdateUserPermissions/UpdateUserPermissions";
import UserDetails from "../../../pages/Users/UserDetails/UserDetails";
import ManageSites from "../../../pages/Sites/ManageSites/ManageSites";
import SiteDetails from "../../../pages/Sites/SiteDetails/SiteDetails";
import CellsManagement from "../../../pages/Configuration/CellsManagement/CellsManagement";
import CitiesManagement from "../../../pages/Configuration/CitiesManagement/CitiesManagement";
import ClosetsManagement from "../../../pages/Configuration/ClosetsManagement/ClosetsManagement";
import FwVersionsManagement from "../../../pages/Configuration/FwVersionsManagement/FwVersionsManagement";
import ClosetDetails from "../../../pages/Configuration/ClosetsManagement/ClosetDetails/ClosetDetails";
import ListManagement from "../../../pages/Configuration/ListManagement/ListManagement";
import ManageOrders from "../../../pages/Orders/ManageOrders/ManageOrders";
import ManageCompounds from "../../../pages/Sites/ManageCompounds/ManageCompounds";
import ManageLockers from "../../../pages/Lockers/ManageLockers/ManageLockers";
import ListOptions from "../../../pages/Configuration/ListManagement/ListOptions/ListOptions";
import OrderHistory from "../../../pages/Orders/OrderHistory/OrderHistory";
import ManageClosetsWithSite from "../../../pages/Sites/ManageClosetsWithSite/ManageClosetsWithSite";
import ClosetDetailsWithSite from "../../../pages/Sites/ClosetDetails/ClosetDetailsWithSite";
import ManageLocks from "../../../pages/Locks/ManageLocks/ManageLocks";
import ChangeExpiredPassword from "../../../pages/Account/ChangePassword/ChangeExpiredPassword";
import ManageWaitingLists from "../../../pages/WaitingLists/ManageWaitingLists/ManageWaitingLists";
import ReleaseMigrationLock from "../../../pages/Locks/ManageLocks/ReleaseMigrationLock";
import AddLock from "../../../pages/Locks/ManageLocks/AddLock";
import PricingPlansManagment from "../../../pages/PricingPlans/PricingPlansManagment/PricingPlansManagment";
import PricingPlanDetails from "../../../pages/PricingPlans/PricingPlanDetails/PricingPlanDetails";
import OrderMessagesHistory from "../../../pages/Orders/OrderMessagesHistory/OrderMessagesHistory";
import OrdersReports from "../../../pages/Reports/OrdersReports/OrdersReports";
import SiteDetailsReports from "../../../pages/Reports/SiteDetailsReports/SiteDetailsReports";
import OrdersBySitesSitesReports from "../../../pages/Reports/OrdersBySitesReports/OrdersBySitesReports";

const RcRouter = () => {
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const routerConfig: IRouteConfig[] = [
    {
      path: CONSTANTS.ROUTES.ROLE_MANAGEMENT.BASE,
      component: (
        <ManageRoles
          permission={appPermissions.includes(PERMISSIONS.GET_ROLES)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_ROLES,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.COMPOUNDS_LIST +
        "/:siteId",
      component: (
        <ManageCompounds
          permission={appPermissions.includes(
            PERMISSIONS.GET_COMPOUNDS_BY_SITE
          )}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_COMPOUNDS_BY_SITE,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CUSTOMERS.BASE +
        "/" +
        CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_LIST,
      component: (
        <CustomerList
          permission={appPermissions.includes(PERMISSIONS.GET_CUSTOMERS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CUSTOMERS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USERS_LIST,
      component: (
        <ManageUsers
          permission={appPermissions.includes(PERMISSIONS.GET_USERS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_USERS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.UPDATE_USER_PERMISSIONS +
        "/:userId",
      component: (
        <UpdateUserPermissions
          permission={appPermissions.includes(
            PERMISSIONS.UPDATE_USER_PERMISSIONS
          )}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.UPDATE_USER_PERMISSIONS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USER_DETAILS,
      component: (
        <UserDetails
          permission={appPermissions.includes(PERMISSIONS.GET_USER_DETAILS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_USER_DETAILS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USER_DETAILS +
        "/:userId",
      component: (
        <UserDetails
          permission={appPermissions.includes(PERMISSIONS.GET_USER_DETAILS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_USER_DETAILS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST,
      component: (
        <ManageOrders
          permission={appPermissions.includes(PERMISSIONS.GET_ORDERS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITES_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_ORDERS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDER_HISTORY +
        "/:orderId",
      component: (
        <OrderHistory
          permission={appPermissions.includes(PERMISSIONS.GET_ORDER_HISTORY)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_ORDER_HISTORY,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDER_MESSAGES_HISTORY +
        "/:orderId",
      component: (
        <OrderMessagesHistory
          permission={appPermissions.includes(PERMISSIONS.GET_ORDER_MESSAGES_HISTORY)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_ORDER_MESSAGES_HISTORY,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITE_DETAILS,
      component: (
        <SiteDetails
          permission={appPermissions.includes(PERMISSIONS.ADD_SITE)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.ADD_SITE,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITE_DETAILS +
        "/:siteId",
      component: (
        <SiteDetails
          permission={appPermissions.includes(PERMISSIONS.GET_SITE_DETAILS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_SITE_DETAILS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITES_LIST,
      component: (
        <ManageSites
          permission={appPermissions.includes(PERMISSIONS.GET_SITES)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_SITES,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_MANAGEMENT,
      component: (
        <ManageClosetsWithSite
          permission={appPermissions.includes(PERMISSIONS.GET_CLOSETS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CLOSETS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_DETAILS,
      component: (
        <ClosetDetailsWithSite
          permission={appPermissions.includes(PERMISSIONS.GET_CLOSET_DETAILS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CLOSET_DETAILS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_DETAILS +
        "/:closetId",
      component: (
        <ClosetDetailsWithSite
          permission={appPermissions.includes(PERMISSIONS.GET_CLOSET_DETAILS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CLOSET_DETAILS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CUSTOMERS.BASE +
        "/" +
        CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS +
        "/:customerId",
      component: (
        <CustomerDetails
          permission={appPermissions.includes(PERMISSIONS.GET_CUSTOMER_DETAILS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CUSTOMER_DETAILS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.ROLE_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.ROLE_MANAGEMENT.CHILDREN.SET_ROLE,
      component: (
        <SetRole
          permission={appPermissions.includes(PERMISSIONS.ADD_ROLE)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.ADD_ROLE,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.ROLE_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.ROLE_MANAGEMENT.CHILDREN.SET_ROLE +
        `/:roleId`,
      component: (
        <SetRole
          permission={appPermissions.includes(PERMISSIONS.UPDATE_ROLE)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.UPDATE_ROLE,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.LIST_MANAGEMENT +
        `/:listId`,
      component: (
        <ListOptions
          permission={appPermissions.includes(
            PERMISSIONS.GET_DYNAMIC_LIST_OPTIONS_BY_KEY
          )}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_DYNAMIC_LIST_OPTIONS_BY_KEY,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.LIST_MANAGEMENT,
      component: (
        <ListManagement
          permission={appPermissions.includes(PERMISSIONS.GET_DYNAMIC_LISTS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_DYNAMIC_LISTS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CELLS_MANAGEMENT,
      component: (
        <CellsManagement
          permission={appPermissions.includes(PERMISSIONS.GET_CELL_FORMATIONS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CELL_FORMATIONS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CITIES_MANAGEMENT,
      component: (
        <CitiesManagement
          permission={appPermissions.includes(PERMISSIONS.GET_CITIES)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CITIES,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CLOSETS_MANAGEMENT,
      component: (
        <ClosetsManagement
          permission={appPermissions.includes(
            PERMISSIONS.GET_CLOSET_FORMATIONS
          )}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CLOSET_FORMATIONS,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CLOSETS_MANAGEMENT +
        "/:closetId",
      component: (
        <ClosetDetails
          permission={appPermissions.includes(
            PERMISSIONS.GET_CLOSET_FORMATION_DETAILS
          )}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CLOSET_FORMATION_DETAILS,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.FW_VERSIONS_MANAGEMENT,
      component: (
        <FwVersionsManagement
          permission={appPermissions.includes(
            PERMISSIONS.GET_FW_VERSION
          )}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.LOCKERS_MANAGEMENT.CHILDREN.LOCKERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_FW_VERSION,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.LOCKERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.LOCKERS_MANAGEMENT.CHILDREN.LOCKERS_LIST,
      component: (
        <ManageLockers
          permission={appPermissions.includes(PERMISSIONS.GET_CELLS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CELLS,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.LOCKS_LIST,
      component: (
        <ManageLocks
          permission={appPermissions.includes(PERMISSIONS.GET_LOCKS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_LOCKS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.RELEASE_MIGRATION_LOCK,
      component: (
        <ReleaseMigrationLock
          permission={appPermissions.includes(PERMISSIONS.RELEASE_MIGRATION)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.RELEASE_MIGRATION_LOCK
          }
        />
      ),
      permission: PERMISSIONS.RELEASE_MIGRATION,
      // nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.ADD_LOCK,
      component: (
        <AddLock
          permission={appPermissions.includes(PERMISSIONS.RELEASE_MIGRATION)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.ADD_LOCK
          }
        />
      ),
      // permission: PERMISSIONS.RELEASE_MIGRATION,
      // nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:
        CONSTANTS.ROUTES.WAITING_LIST_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.WAITING_LIST_MANAGEMENT.CHILDREN.WAITING_LIST,
      component: (
        <ManageWaitingLists
          permission={appPermissions.includes(PERMISSIONS.GET_WAITING_LISTS)}
          nextRoute={
            "/" +
            CONSTANTS.ROUTES.WAITING_LIST_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.WAITING_LIST_MANAGEMENT.CHILDREN.WAITING_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_WAITING_LISTS,
      nextRoute: "/" + CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path: `${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLANS_LIST}`,
      component: (
        <PricingPlansManagment
          permission={appPermissions.includes(PERMISSIONS.GET_PRICING_PLANS)}
          nextRoute={`/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLANS_LIST}`}
        />
      ),
      permission: PERMISSIONS.GET_PRICING_PLANS,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path: `${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLAN_DETAILS}/:pricePlanId`,
      component: (
        <PricingPlanDetails
          permission={appPermissions.includes(
            PERMISSIONS.GET_PRICING_PLAN_DETAILS
          )}
          nextRoute={`/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLANS_LIST}`}
        />
      ),
      permission: PERMISSIONS.GET_PRICING_PLAN_DETAILS,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:`${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLAN_DETAILS}`,
      component: (
        <PricingPlanDetails
          permission={appPermissions.includes(
            PERMISSIONS.GET_PRICING_PLAN_DETAILS
          )}
          nextRoute={`${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLANS_LIST}`}
        />
      ),
      permission: PERMISSIONS.GET_PRICING_PLAN_DETAILS,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    // Reports
    {
      path:`${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.ORDERS_REPORTS}`,
      component: (
        <OrdersReports
          permission={appPermissions.includes(
            PERMISSIONS.GET_EXCEL_ORDERS_REPORT
          )}
          nextRoute={`${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.ORDERS_REPORTS}`}
        />
      ),
      permission: PERMISSIONS.GET_EXCEL_ORDERS_REPORT,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:`${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.ORDERS_BY_SITES_REPORTS}`,
      component: (
        <OrdersBySitesSitesReports
          permission={appPermissions.includes(
            PERMISSIONS.GET_EXCEL_SITES_ORDERS_CONCENTRATION_REPORT
          )}
          nextRoute={`${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.ORDERS_BY_SITES_REPORTS}`}
        />
      ),
      permission: PERMISSIONS.GET_EXCEL_SITES_ORDERS_CONCENTRATION_REPORT,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path:`${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.SITE_DETAILS_REPORTS}`,
      component: (
        <SiteDetailsReports
          permission={appPermissions.includes(
            PERMISSIONS.GET_PDF_SITE_REPORT
          )}
          nextRoute={`${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.SITE_DETAILS_REPORTS}`}
        />
      ),
      permission: PERMISSIONS.GET_PDF_SITE_REPORT,
      nextRoute: CONSTANTS.ROUTES.HOME.BASE,
    },
    {
      path: "*",
      component: (
        <Navigate
          replace
          to={
            "/" +
            CONSTANTS.ROUTES.CUSTOMERS.BASE +
            "/" +
            CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CUSTOMERS,
    },
    {
      path: "/",
      component: (
        <Navigate
          replace
          to={
            "/" +
            CONSTANTS.ROUTES.CUSTOMERS.BASE +
            "/" +
            CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_LIST
          }
        />
      ),
      permission: PERMISSIONS.GET_CUSTOMERS,
    },
  ];

  // We will show a route by its permissions
  return (
    <Routes>
      <Route path="/" element={<GuardedRoute routes={routerConfig} />}>
        {routerConfig.map((routeConf) => {
          const route = (
            <Route
              key={routeConf.path}
              path={routeConf?.path}
              element={routeConf.component}
            />
          );

          // const fallbackRoute = (
          //   <Route
          //     key={routeConf.path}
          //     path={routeConf?.nextRoute}
          //     element={routeConf?.nextComponent}
          //   />
          // );
          // if (
          //   !appPermissions.length ||
          //   !appPermissions ||
          //   !routeConf.permission
          // )
          //   return route;

          // if (typeof routeConf?.permission === "string") {
          //   return appPermissions.includes(routeConf?.permission)
          //     ? route
          //     : fallbackRoute;
          // } else if (
          //   Array.isArray(routeConf?.permission) &&
          //   routeConf?.permission
          // ) {
          //   return routeConf?.permission.every((v) =>
          //     appPermissions.includes(v)
          //   )
          //     ? route
          //     : fallbackRoute;
          // }

          return route;
        })}
      </Route>
    </Routes>
  );
};

export default RcRouter;
