import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FwVersion from "./FwVersion";
import { useAppSelector } from "../../../store";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import Title from "../../../components/UI/Title/Title";
import { PERMISSIONS } from "../../../utils/permissions";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { IPage } from "../../../models/page";
import { CONSTANTS } from "../../../utils/constants";

import "./FwVersion.scss";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { IFwVersion, IFwVersionMetaData } from "../../../models/fwVersion";
import { IDynamicList } from "../../../store/entities/general/type";
import { GeneralActions } from "../../../store/entities/general/general.actions";

const FwVersionsManagement = ({ permission, nextRoute }: IPage) => {
  const { fwVersionsMetaData } = useAppSelector((state) => state.configuration);
  const { appLanguage } = useAppSelector((store) => store.general);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fwVersionsMetaDataLists, setFwVersionsMetaDataLists] = useState<IFwVersionMetaData[]>([]);
  const pemTypes = useAppSelector((state) => state.general.pemTypes);
  const [pemTypeItems, setPemTypesItems] = useState<IAutoCompleteItem[]>([]);

  useEffect(() => {
    dispatch(GeneralActions.getPemTypes());
  }, [dispatch]);

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(ConfigurationActions.getFwVersionsMetaDataLists());
  }, [dispatch]);

  useEffect(() => {
    setFwVersionsMetaDataLists(fwVersionsMetaData);
  }, [fwVersionsMetaData]);

  useEffect(() => {
    if (pemTypes.length) {
      const pems: IAutoCompleteItem[] = pemTypes.map((pem: IDynamicList) => ({
        id: pem?.id,
        label:
          appLanguage === CONSTANTS.DEFAULT_LANGUAGE
            ? pem.nameHE
            : pem.nameEN ?? "",
      }));
      setPemTypesItems(pems);
    }
  }, [appLanguage, pemTypes]);

  const addNewFwVersion = () => {
    if (fwVersionsMetaDataLists.some((fwVersionMetaData) => fwVersionMetaData.id === null)) {
      return;
    }
    const newFwVersion: IFwVersion = {
      id: null,
      fileName: "",
      title: "",
      pemType: "",
      version: "",
      isActive: false,
      file: "",
      created: null,
    };
    setFwVersionsMetaDataLists([newFwVersion, ...fwVersionsMetaDataLists]);
  };

  const generalButtons = [
    {
      title: "configuration.fwVersionsConfiguration.addNewVersion",
      onClick: addNewFwVersion,
      show: appPermissions.includes(PERMISSIONS.ADD_FW_VERSION),
    },
  ];

  return (
    <>
      <Title text={"configuration.fwVersionsConfiguration.pageTitle"} />

      <TableButtons
        generalButtons={generalButtons}
        className="manageFwVersions__button"
        tableButtons={[]}
      />

      {fwVersionsMetaDataLists?.map((item: IFwVersionMetaData, index: number) => (
        <FwVersion
          key={index}
          fwVersionMetaData={item}
          fwVersionsMetaDataLists={fwVersionsMetaDataLists}
          pemsList={pemTypeItems}
        />
      ))}
    </>
  );
};

export default FwVersionsManagement;
