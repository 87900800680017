import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFilePicker } from "use-file-picker";

import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import Alert from "../../../components/UI/Alert/Alert";
import Form from "../../../components/Container/Form/Form";
import {
  IFwVersionMetaData,
  IFwVersion,
  IFwVersionManagement,
} from "../../../models/fwVersion";
import { FieldTypes, IFormFields } from "../../../models/form";
import { fwVersionDetailsValidation } from "../../../utils/formValidation/fwVersionTemplateDetails";
import useTranslations from "../../../hooks/useTranslations";
import { useAppSelector } from "../../../store";
import { PERMISSIONS } from "../../../utils/permissions";

import "./FwVersion.scss";
import { IApiResponse } from "../../../models/api";

const FwVersion = (props: IFwVersionManagement) => {
  const { fwVersionMetaData, fwVersionsMetaDataLists, pemsList } = props;

  const [show, setShow] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { formikProps } = useAppSelector((store) => store.form);

  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();

  const edit = Boolean(fwVersionMetaData?.id);

  const { openFilePicker } = useFilePicker({
    multiple: false,
    accept: ".zip",
    readAs: "DataURL",
    onFilesSuccessfullySelected: ({ filesContent }) => {
      const file = filesContent[0];
      formikProps.setFieldValue("file", file.content.split(",")[1]);
      formikProps.setFieldValue("fileName", file.name);
    },
  });

  const fields: IFormFields = {
    initialValues: {
      id: fwVersionMetaData?.id ?? "",
      title: fwVersionMetaData?.title ?? "",
      version: fwVersionMetaData?.version ?? "",
      pemType: fwVersionMetaData?.pemType ?? "",
      created: fwVersionMetaData?.created,
      fileName: fwVersionMetaData?.fileName,
      isActive: fwVersionMetaData?.isActive ?? false,
    },
    formData: [
      {
        type: FieldTypes.FORM_CONTROL,
        id: "id",
        label: edit
          ? "configuration.fwVersionsConfiguration.fieldNames.id"
          : "configuration.fwVersionsConfiguration.fieldNames.new",
      },
      {
        type: FieldTypes.TEXT,
        id: "title",
        label: "configuration.fwVersionsConfiguration.fieldNames.title",
        props: { disabled: edit },
      },
      {
        type: FieldTypes.TEXT,
        id: "version",
        label: "configuration.fwVersionsConfiguration.fieldNames.version",
        props: { disabled: edit },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "pemType",
        label: "configuration.fwVersionsConfiguration.fieldNames.pemList",
        items: pemsList,
        props: { multiple: false, disabled: edit },
      },
      ...(edit
        ? [
            {
              type: FieldTypes.DATE,
              id: "created",
              label:
                "configuration.fwVersionsConfiguration.fieldNames.uploadDate",
              props: { disabled: edit },
            },
          ]
        : [
            {
              type: FieldTypes.BUTTON,
              id: "file",
              label:
                "configuration.fwVersionsConfiguration.fieldNames.uploadFile",
              onClick: openFilePicker,
            },
          ]),
      {
        type: FieldTypes.TEXT,
        id: "fileName",
        label: "configuration.fwVersionsConfiguration.fieldNames.fileName",
        props: { disabled: true },
      },
      {
        type: FieldTypes.CHECK_BOX,
        id: "isActive",
        label: "configuration.commonFieldNames.isActive",
        disabled: edit,
      },
    ],
  };

  const addNewFwVersionDetails = async (
    values: IFwVersion,
    setSubmitting: (value: boolean) => void
  ) => {
    let uniqueError = false;
    const { setFieldError } = formikProps;
    setSubmitting(false);

    fwVersionsMetaDataLists.forEach((fwVersion: IFwVersionMetaData) => {
      if (
        fwVersion?.pemType === values?.pemType &&
        fwVersion?.version === values?.version &&
        fwVersion?.id !== values?.id
      ) {
        uniqueError = true;
        setFieldError(
          "pemType",
          rcTranslate(
            "configuration.fwVersionsConfiguration.alreadyTakenVersioAndPemError"
          )
        );
        setFieldError(
          "version",
          rcTranslate(
            "configuration.fwVersionsConfiguration.alreadyTakenVersioAndPemError"
          )
        );
      }
    });

    if (!uniqueError) {
      const res = (await dispatch(
        ConfigurationActions.addFwVersion(values)
      )) as unknown as IApiResponse;
      setAlertMessage("configuration.fwVersionsConfiguration.addSuccess");

      if (res && res.isSucceeded) {
        setShow(true);
        await dispatch(ConfigurationActions.getFwVersionsMetaDataLists());
      }
    }

    setTimeout(() => {
      setShow(false);
    }, 5000);
  };

  return (
    <>
      <div className="manageFwVersions__compoundWrapper">
        <Form
          formClassName="manageFwVersions__form"
          fields={fields}
          formValidation={fwVersionDetailsValidation}
          onSubmit={addNewFwVersionDetails}
          submitButtonTitle="button.save"
          showSubmitButton={!edit}
          isDisabled={!appPermissions.includes(PERMISSIONS.ADD_FW_VERSION)}
        />
      </div>
      {show && <Alert message={rcTranslate(alertMessage)} color="success" />}
    </>
  );
};
export default FwVersion;
