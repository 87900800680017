import { ApiService } from "../../../services/api";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  GetDynamicListsOptionPayload,
  GetDynamicListsPayload,
  GetCellsListsPayload,
  GetClosetsListsPayload,
  ClosetDetails,
  UpdateDynamicListOptionBody,
  SelectedCell,
  cellsToClosetError,
  ClosetCells,
  ConfigurationState,
  GetFwVersionsMetaDataListsPayload
} from "./type";
import { ICell, ICellUpdate } from "../../../models/cell";
import { ICloset, IClosetUpdate } from "../../../models/closet";
import { getErrorMessages } from "../../../utils/formatters";
import { IApiError } from "../../../models/api";
import { IFwVersion, IFwVersionMetaData } from "../../../models/fwVersion";

const { get, put, post } = ApiService;

enum ConfigurationActionsEnum {
  GET_DYNAMIC_LIST_OPTIONS_BY_KEY = "GET_DYNAMIC_LIST_OPTIONS_BY_KEY",
  GET_DYNAMIC_LISTS = "GET_DYNAMIC_LISTS",
  GET_DYNAMIC_LIST_OPTIONS = "GET_DYNAMIC_LIST_OPTIONS",
  UPDATE_DYNAMIC_LIST_OPTION = "UPDATE_DYNAMIC_LIST_OPTION",
  GET_CELLS_LISTS = "GET_CELLS_LISTS",
  UPDATE_CELL = "UPDATE_CELL",
  ADD_CELL = "ADD_CELL",
  GET_FW_VERSIONS_META_DATA_LIST = "GET_FW_VERSIONS_META_DATA_LIST",
  ADD_FW_VERSION = "ADD_FW_VERSION",
  GET_CLOSETS_LIST = "GET_CLOSETS_LIST",
  ADD_CLOSET = "ADD_CLOSET",
  UPDATE_CLOSET = "UPDATE_CLOSET",
  GET_CLOSET_DETAILS = "GET_CLOSET_DETAILS",
  UPDATE_CELLS_TO_CLOSET = "UPDATE_CELLS_TO_CLOSET",
  UPDATE_CLOSET_DETAILS = "UPDATE_CLOSET_DETAILS",
  SELECTED_COLUMN = "SELECTED_COLUMN",
  SELECTED_CELL = "SELECTED_CELL",
  CELLS_TO_CLOSET_ERRORS = "CELLS_TO_CLOSET_ERRORS",
  GET_CLOSET_CELLS = "GET_CLOSET_CELLS",
  SET_CONFIGURATION_DATA="SET_CONFIGURATION_DATA"
}

export const ConfigurationActionsNames = {
  [ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS_BY_KEY]:
    generateAsyncActionNames(
      ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS_BY_KEY
    ),
  [ConfigurationActionsEnum.GET_DYNAMIC_LISTS]: generateAsyncActionNames(
    ConfigurationActionsEnum.GET_DYNAMIC_LISTS
  ),
  [ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS]: generateAsyncActionNames(
    ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS
  ),
  [ConfigurationActionsEnum.UPDATE_DYNAMIC_LIST_OPTION]:
    generateAsyncActionNames(
      ConfigurationActionsEnum.UPDATE_DYNAMIC_LIST_OPTION
    ),
  [ConfigurationActionsEnum.GET_CELLS_LISTS]: generateAsyncActionNames(
    ConfigurationActionsEnum.GET_CELLS_LISTS
  ),
  [ConfigurationActionsEnum.UPDATE_CELL]: generateAsyncActionNames(
    ConfigurationActionsEnum.UPDATE_CELL
  ),
  [ConfigurationActionsEnum.ADD_CELL]: generateAsyncActionNames(
    ConfigurationActionsEnum.ADD_CELL
  ),
  [ConfigurationActionsEnum.GET_FW_VERSIONS_META_DATA_LIST]: generateAsyncActionNames(
    ConfigurationActionsEnum.GET_FW_VERSIONS_META_DATA_LIST
  ),
  [ConfigurationActionsEnum.ADD_FW_VERSION]: generateAsyncActionNames(
    ConfigurationActionsEnum.ADD_FW_VERSION
  ),
  [ConfigurationActionsEnum.GET_CLOSETS_LIST]: generateAsyncActionNames(
    ConfigurationActionsEnum.GET_CLOSETS_LIST
  ),
  [ConfigurationActionsEnum.ADD_CLOSET]: generateAsyncActionNames(
    ConfigurationActionsEnum.ADD_CLOSET
  ),
  [ConfigurationActionsEnum.UPDATE_CLOSET]: generateAsyncActionNames(
    ConfigurationActionsEnum.UPDATE_CLOSET
  ),
  [ConfigurationActionsEnum.GET_CLOSET_DETAILS]: generateAsyncActionNames(
    ConfigurationActionsEnum.GET_CLOSET_DETAILS
  ),
  [ConfigurationActionsEnum.UPDATE_CELLS_TO_CLOSET]: generateAsyncActionNames(
    ConfigurationActionsEnum.UPDATE_CELLS_TO_CLOSET
  ),
  [ConfigurationActionsEnum.UPDATE_CLOSET_DETAILS]: generateAsyncActionNames(
    ConfigurationActionsEnum.UPDATE_CLOSET_DETAILS
  ),
  [ConfigurationActionsEnum.SELECTED_COLUMN]: generateAsyncActionNames(
    ConfigurationActionsEnum.SELECTED_COLUMN
  ),
  [ConfigurationActionsEnum.SELECTED_CELL]: generateAsyncActionNames(
    ConfigurationActionsEnum.SELECTED_CELL
  ),
  [ConfigurationActionsEnum.CELLS_TO_CLOSET_ERRORS]: generateAsyncActionNames(
    ConfigurationActionsEnum.CELLS_TO_CLOSET_ERRORS
  ),
  [ConfigurationActionsEnum.GET_CLOSET_CELLS]: generateAsyncActionNames(
    ConfigurationActionsEnum.GET_CLOSET_CELLS
  ),
  [ConfigurationActionsEnum.SET_CONFIGURATION_DATA]: generateAsyncActionNames(
    ConfigurationActionsEnum.SET_CONFIGURATION_DATA
  )
};

const Actions = {
  [ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS_BY_KEY]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS_BY_KEY.START
    }),
    FULFILLED: (data: GetDynamicListsPayload) => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS_BY_KEY.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS_BY_KEY.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.GET_DYNAMIC_LISTS]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LISTS.START
    }),
    FULFILLED: (data: GetDynamicListsPayload) => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LISTS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LISTS.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS.START
    }),
    FULFILLED: (data: GetDynamicListsOptionPayload) => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.UPDATE_DYNAMIC_LIST_OPTION]: {
    START: () => ({
      type: ConfigurationActionsNames.UPDATE_DYNAMIC_LIST_OPTION.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.UPDATE_DYNAMIC_LIST_OPTION.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.UPDATE_DYNAMIC_LIST_OPTION.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.GET_CELLS_LISTS]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_CELLS_LISTS.START
    }),
    FULFILLED: (data: GetCellsListsPayload) => ({
      type: ConfigurationActionsNames.GET_CELLS_LISTS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_CELLS_LISTS.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.UPDATE_CELL]: {
    START: () => ({
      type: ConfigurationActionsNames.UPDATE_CELL.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.UPDATE_CELL.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.UPDATE_CELL.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.ADD_CELL]: {
    START: () => ({
      type: ConfigurationActionsNames.ADD_CELL.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.ADD_CELL.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.ADD_CELL.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.GET_FW_VERSIONS_META_DATA_LIST]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_FW_VERSIONS_META_DATA_LIST.START
    }),
    FULFILLED: (data: GetFwVersionsMetaDataListsPayload) => ({
      type: ConfigurationActionsNames.GET_FW_VERSIONS_META_DATA_LIST.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_FW_VERSIONS_META_DATA_LIST.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.ADD_FW_VERSION]: {
    START: () => ({
      type: ConfigurationActionsNames.ADD_FW_VERSION.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.ADD_FW_VERSION.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.ADD_FW_VERSION.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.GET_CLOSETS_LIST]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_CLOSETS_LIST.START
    }),
    FULFILLED: (data: GetClosetsListsPayload) => ({
      type: ConfigurationActionsNames.GET_CLOSETS_LIST.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_CLOSETS_LIST.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.ADD_CLOSET]: {
    START: () => ({
      type: ConfigurationActionsNames.ADD_CLOSET.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.ADD_CLOSET.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.ADD_CLOSET.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.UPDATE_CLOSET]: {
    START: () => ({
      type: ConfigurationActionsNames.UPDATE_CLOSET.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.UPDATE_CLOSET.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.UPDATE_CLOSET.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.GET_CLOSET_DETAILS]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_CLOSET_DETAILS.START
    }),
    FULFILLED: (data: ClosetDetails) => ({
      type: ConfigurationActionsNames.GET_CLOSET_DETAILS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_CLOSET_DETAILS.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.UPDATE_CELLS_TO_CLOSET]: {
    START: () => ({
      type: ConfigurationActionsNames.UPDATE_CELLS_TO_CLOSET.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.UPDATE_CELLS_TO_CLOSET.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.UPDATE_CELLS_TO_CLOSET.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.UPDATE_CLOSET_DETAILS]: {
    FULFILLED: (data: ClosetDetails | null) => ({
      type: ConfigurationActionsNames.UPDATE_CLOSET_DETAILS.FULFILLED,
      payload: data
    })
  },
  [ConfigurationActionsEnum.SELECTED_COLUMN]: {
    FULFILLED: (id: number | null) => ({
      type: ConfigurationActionsNames.SELECTED_COLUMN.FULFILLED,
      payload: id
    })
  },
  [ConfigurationActionsEnum.SELECTED_CELL]: {
    FULFILLED: (selectedCell: SelectedCell | null) => ({
      type: ConfigurationActionsNames.SELECTED_CELL.FULFILLED,
      payload: selectedCell
    })
  },
  [ConfigurationActionsEnum.CELLS_TO_CLOSET_ERRORS]: {
    FULFILLED: (data: cellsToClosetError[] | []) => ({
      type: ConfigurationActionsNames.CELLS_TO_CLOSET_ERRORS.FULFILLED,
      payload: data
    })
  },
  [ConfigurationActionsEnum.GET_CLOSET_CELLS]: {
    START: () => ({
      type: ConfigurationActionsNames.GET_CLOSET_CELLS.START
    }),
    FULFILLED: () => ({
      type: ConfigurationActionsNames.GET_CLOSET_CELLS.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.GET_CLOSET_CELLS.REJECTED,
      payload: error
    })
  },
  [ConfigurationActionsEnum.SET_CONFIGURATION_DATA]: {
    START: () => ({
      type: ConfigurationActionsNames.SET_CONFIGURATION_DATA.START
    }),
    FULFILLED: (data: Partial<ConfigurationState>) => ({
      type: ConfigurationActionsNames.SET_CONFIGURATION_DATA.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ConfigurationActionsNames.SET_CONFIGURATION_DATA.REJECTED,
      payload: error
    })
  },
};

const getDynamicListOptionsByKey =
  (key: string) => async (dispatch: AppDispatch) => {
    dispatch(
      Actions[ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS_BY_KEY].START()
    );
    try {
      const { data }: { data: ApiResponse } = await get(
        `DynamicList/GetDynamicListOptionsByKey?key=${key}`
      );
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(
        Actions[
          ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS_BY_KEY
        ].FULFILLED(data?.content?.dynamicListOptions)
      );
    } catch (error: any) {
      dispatch(
        Actions[
          ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS_BY_KEY
        ].REJECTED(getErrorMessages(error))
      );
    }
  };

const getDynamicLists = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.GET_DYNAMIC_LISTS].START());

  try {
    const { data }: { data: ApiResponse } = await get(
      "DynamicList/GetDynamicLists"
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }
    dispatch(
      Actions[ConfigurationActionsEnum.GET_DYNAMIC_LISTS].FULFILLED(
        data?.content?.dyanmicLists
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.GET_DYNAMIC_LISTS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getDynamicListOptions =
  (listId: any) => async (dispatch: AppDispatch) => {
    dispatch(
      Actions[ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS].START()
    );

    try {
      const { data }: { data: ApiResponse } = await get(
        `DynamicList/GetDynamicListOptions/${listId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(
        Actions[ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS].FULFILLED(
          data?.content
        )
      );
    } catch (error: any) {
      dispatch(
        Actions[ConfigurationActionsEnum.GET_DYNAMIC_LIST_OPTIONS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateDynamicListOption =
  (listId: number, optionItemId: number, body: UpdateDynamicListOptionBody) =>
  async (dispatch: AppDispatch) => {
    dispatch(
      Actions[ConfigurationActionsEnum.UPDATE_DYNAMIC_LIST_OPTION].START()
    );

    try {
      const { data }: { data: ApiResponse } = await put(
        `DynamicList/UpdateDynamicListOption/${listId}/${optionItemId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(
        Actions[ConfigurationActionsEnum.UPDATE_DYNAMIC_LIST_OPTION].FULFILLED()
      );
      return data;
    } catch (error: any) {
      dispatch(
        Actions[ConfigurationActionsEnum.UPDATE_DYNAMIC_LIST_OPTION].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };
const getCellsLists = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.GET_CELLS_LISTS].START());

  try {
    const { data }: any = await get("CellFormation/GetCellFormations");
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }
    const cells = data?.content?.cellFormations;
    dispatch(
      Actions[ConfigurationActionsEnum.GET_CELLS_LISTS].FULFILLED(cells)
    );
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.GET_CELLS_LISTS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const updateCell =
  (cellId: string | number, body: ICellUpdate) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[ConfigurationActionsEnum.UPDATE_CELL].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `CellFormation/UpdateCellFormation/${cellId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(Actions[ConfigurationActionsEnum.UPDATE_CELL].FULFILLED());
      return data;
    } catch (error: any) {
      dispatch(
        Actions[ConfigurationActionsEnum.UPDATE_CELL].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const addCell = (body: ICell) => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.ADD_CELL].START());

  try {
    const { data }: { data: ApiResponse } = await post(
      "CellFormation/AddCellFormation",
      body
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[ConfigurationActionsEnum.ADD_CELL].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.ADD_CELL].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getFwVersionsMetaDataLists = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.GET_FW_VERSIONS_META_DATA_LIST].START());

  try {
    const { data }: any = await get("FwVersion/GetFwVersionsMetaData");
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }
    const fwVersions = data?.content?.fwVersions;
    dispatch(
      Actions[ConfigurationActionsEnum.GET_FW_VERSIONS_META_DATA_LIST].FULFILLED(fwVersions)
    );
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.GET_FW_VERSIONS_META_DATA_LIST].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const addFwVersion = (body: IFwVersion) => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.ADD_FW_VERSION].START());

  try {
    const { data }: { data: ApiResponse } = await post(
      "FwVersion/AddFwVersion",
      body
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[ConfigurationActionsEnum.ADD_FW_VERSION].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.ADD_FW_VERSION].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getClosetCells = (closetNumber: number) => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.GET_CLOSET_CELLS].START());

  try {
    const { data }: { data: ApiResponse } = await get(
      `Cell/GetCells?ClosetNumber=${closetNumber}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[ConfigurationActionsEnum.GET_CLOSET_CELLS].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.GET_CLOSET_CELLS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getClosetsLists = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.GET_CLOSETS_LIST].START());

  try {
    const { data }: any = await get("ClosetFormation/GetClosetFormations");
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }
    const closets = data?.content?.closetFormations;
    dispatch(
      Actions[ConfigurationActionsEnum.GET_CLOSETS_LIST].FULFILLED(closets)
    );
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.GET_CLOSETS_LIST].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const setConfigurationsData =
  (sitesData: Partial<ConfigurationState>) => async (dispatch: AppDispatch) => {
    try {
      dispatch(Actions[ConfigurationActionsEnum.SET_CONFIGURATION_DATA].START());

      dispatch(Actions[ConfigurationActionsEnum.SET_CONFIGURATION_DATA].FULFILLED(sitesData));
    } catch (error: any) {
      dispatch(
        Actions[ConfigurationActionsEnum.SET_CONFIGURATION_DATA].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };


const addCloset = (body: ICloset) => async (dispatch: AppDispatch) => {
  dispatch(Actions[ConfigurationActionsEnum.ADD_CLOSET].START());

  try {
    const { data }: { data: ApiResponse } = await post(
      "ClosetFormation/AddClosetFormation",
      body
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[ConfigurationActionsEnum.ADD_CLOSET].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[ConfigurationActionsEnum.ADD_CLOSET].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const updateCloset =
  (closetId: string | number, body: IClosetUpdate) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[ConfigurationActionsEnum.UPDATE_CLOSET].START());
    try {
      const { data }: { data: ApiResponse } = await put(
        `ClosetFormation/UpdateClosetFormation/${closetId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(Actions[ConfigurationActionsEnum.UPDATE_CLOSET].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[ConfigurationActionsEnum.UPDATE_CLOSET].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };
const getClosetDetails =
  (closetId: string | number) => async (dispatch: AppDispatch) => {
    dispatch(Actions[ConfigurationActionsEnum.GET_CLOSET_DETAILS].START());
    try {
      const { data }: { data: ApiResponse } = await get(
        `ClosetFormation/GetClosetFormationDetails/${closetId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(
        Actions[ConfigurationActionsEnum.GET_CLOSET_DETAILS].FULFILLED(
          data?.content
        )
      );
    } catch (error: any) {
      dispatch(
        Actions[ConfigurationActionsEnum.GET_CLOSET_DETAILS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };
const updateClosetDetails =
  (closetDetails: ClosetDetails | null) => async (dispatch: AppDispatch) => {
    dispatch(
      Actions[ConfigurationActionsEnum.UPDATE_CLOSET_DETAILS].FULFILLED(
        closetDetails
      )
    );
  };

const updateSelectedColumnId =
  (id: number | null) => async (dispatch: AppDispatch) => {
    dispatch(
      Actions[ConfigurationActionsEnum.SELECTED_COLUMN].FULFILLED(id ?? null)
    );
  };

const updateSelectedCellId =
  (selectedCell: SelectedCell | null) => async (dispatch: AppDispatch) => {
    dispatch(
      Actions[ConfigurationActionsEnum.SELECTED_CELL].FULFILLED(
        selectedCell ?? null
      )
    );
  };

const updateCellsToCloset =
  (closetId: string | number, body: ClosetDetails) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[ConfigurationActionsEnum.UPDATE_CELLS_TO_CLOSET].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `ClosetFormation/UpdateClosetFormationCellFormations/${closetId}`,
        body
      );
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(
        Actions[ConfigurationActionsEnum.UPDATE_CELLS_TO_CLOSET].FULFILLED()
      );

      return data;
    } catch (error: any) {
      dispatch(
        Actions[ConfigurationActionsEnum.UPDATE_CELLS_TO_CLOSET].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };
const cellToClosetErrors =
  (cellsErrors: cellsToClosetError[] | []) => async (dispatch: AppDispatch) => {
    dispatch(
      Actions[ConfigurationActionsEnum.CELLS_TO_CLOSET_ERRORS].FULFILLED(
        cellsErrors
      )
    );
  };

export const ConfigurationActions = {
  getDynamicListOptionsByKey,
  getDynamicLists,
  getDynamicListOptions,
  updateDynamicListOption,
  getCellsLists,
  updateCell,
  addCell,
  getFwVersionsMetaDataLists,
  addFwVersion,
  getClosetsLists,
  addCloset,
  updateCloset,
  getClosetDetails,
  updateClosetDetails,
  updateCellsToCloset,
  updateSelectedColumnId,
  updateSelectedCellId,
  cellToClosetErrors,
  getClosetCells,
  setConfigurationsData
};
