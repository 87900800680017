import React, {
    useState,
    useEffect,
} from "react";
import "./UpdateLockers.scss";
import DescendingHierarchyForm, { DescendingHierarchyFormEnum, IselectedData } from "../../../../components/Container/descendingHierarchyForm/descendingHierarchyForm";
import { useAppSelector } from "../../../../store";
import { CONSTANTS } from "../../../../utils/constants";
import { Cell, Closet, Compound } from "../../../../store/entities/orders/type";
import { CellStatus } from "../../../../models/cell";
import { LockersActions } from "../../../../store/entities/lockers/lockers.actions";
import { ConfigurationActions } from "../../../../store/entities/configuration/configuration.actions";
import { useDispatch } from "react-redux";
import Dialog from "../../../../components/UI/Dialog/Dialog";
import useTranslations from "../../../../hooks/useTranslations";
import { GeneralActions } from "../../../../store/entities/general/general.actions";
import { ICity } from "../../../../store/entities/general/type";

interface Iprops {
    showDialog: Function;
    type: DescendingHierarchyFormEnum;
}

interface IpropsUpdateAlert {
    siteId: number | null;
    compoundId: number | null;
    closetId: number | null;
    cellId: number | null;
    notes: string;
    type: DescendingHierarchyFormEnum;
}



interface IblockedCellsItemRespond {
    cellId: number;
    isBlockedSucceeded: boolean;
}

const UpdateLockers = (props: Iprops) => {
    const [showBlockReport,setShowBlockReport] = useState<boolean>(false)
    const dispatch = useDispatch();
    const [blockRespond, setBlockRespond] = useState<IblockedCellsItemRespond[]>([] as IblockedCellsItemRespond[])
    const [formSelectedData,setFormSelectedData] = useState<IselectedData>({} as IselectedData);

    const saveUpdateLockers = async (selectedData: IselectedData) => {
        if (selectedData.notes) {
            setFormSelectedData(selectedData);
            switch (props.type) {
                case DescendingHierarchyFormEnum.BLOCK:
                    const responseData: any = await dispatch(LockersActions.postBlockCells(selectedData));
                    setBlockRespond(responseData.blockedCells);
                    setShowBlockReport(true);
                    break;

                case DescendingHierarchyFormEnum.UNPAIR:
                    await dispatch(LockersActions.putUnpairLocksFromCells(selectedData));
                    props.showDialog(false);
                    break;

                case DescendingHierarchyFormEnum.ALLOCATE:
                case DescendingHierarchyFormEnum.RELEASE:
                default:
                    await dispatch(LockersActions.postReleaseBlockCells(selectedData));
                    props.showDialog(false);
                    break;
            }
            await dispatch(ConfigurationActions.getCellsLists());
        }
    }

    return (
        <>
            <DescendingHierarchyForm showDialog={props.showDialog} type={props.type} submitForm={saveUpdateLockers}/>
            {showBlockReport && blockRespond.length ? 
                <BlockReport selectedData={formSelectedData}  showDialog={props.showDialog} setShowBlockReport={setShowBlockReport} blockRespond={blockRespond} /> : null
            }
        </>
    )
}

export const BlockReport = ({showDialog,setShowBlockReport,blockRespond,selectedData} : 
{showDialog: Function,setShowBlockReport:Function,blockRespond: IblockedCellsItemRespond[],selectedData: IselectedData})=> {
    const [succededCells,setSuccededCells] = useState([] as IblockedCellsItemRespond[]);
    const [failedCells,setFailedCells] = useState([] as IblockedCellsItemRespond[]);
    const { rcTranslate } = useTranslations();
    const { siteHierarchy } = useAppSelector((state) => state.general);
    const dispatch = useDispatch();


    useEffect(()=>{
        const callSite = async()=> {
            if(selectedData.siteId)
             await dispatch(GeneralActions.getSiteHierarchyforOrder(selectedData.siteId));
        }
        callSite();
    },[selectedData])

    useEffect(()=> {
      printDialog();
    },[siteHierarchy])

    const printDialog = ()=> {
        return (
            <>
                <Dialog
                    className="blockReport"
                    title={rcTranslate("lockers.blockReport.title")}
                    body={reportBody()}
                    confirmTitle={"button.confirm"}
                    onConfirm={closeDialogs}
                    onClose={closeDialogs}
                    showCloseButton={false}
                />
            </>
        )
    }


    useEffect(()=>{
        if(blockRespond.length) {
            setSuccededCells(filterLockers(true));
            setFailedCells(filterLockers(false));
        }
    },[blockRespond])

    const filterLockers = (status: boolean)=> {
      return blockRespond.filter((blockedCell)=> blockedCell.isBlockedSucceeded === status)
    }

    const getCellNumber = (id: number)=> {
        let cellString = "";
        siteHierarchy?.compounds?.forEach((comp: Compound) => {
              comp.closets.forEach(closet => {
                closet.cells.forEach(currCell => {
                    if(currCell.id===id)
                    cellString = `${closet?.closetNumber}-${currCell?.position}`;
                     
                })
              })
            });
            return cellString
    }

    const reportBody = ()=> {
        return (
            <>
                <div className="report success">
                    <h4 className="blockedTitle">{rcTranslate("lockers.blockReport.succededTitle")}
                        <span className="blockedNumber">{`(${succededCells.length})`}</span>
                    </h4>
                    { 
                       succededCells ?
                       succededCells.map(cellBlocked => (
                            <div className="reportRow">
                                <div> {getCellNumber(cellBlocked.cellId)} </div>
                            </div>
                        )) : <p>{rcTranslate("lockers.blockReport.noSuccess")}</p>
                    }
                </div>
                <div className="report success">
                    <h4>{rcTranslate("lockers.blockReport.failedTitle")}</h4>
                    {
                        failedCells.length?
                        failedCells.map(cellBlocked => (
                            <div className="reportRow">
                                <div> {getCellNumber(cellBlocked.cellId)} </div>
                            </div>
                        )) : <p className="reportRow">{rcTranslate("lockers.blockReport.noFailed")}</p>
                    }
                </div>
            </>
        )
    }
    const closeDialogs = ()=> {
        setShowBlockReport(false);
        showDialog(false);
    }
    return (
        <>{printDialog()}</>
    )
}

export const UpdateLockersAlert = (selectedData: IpropsUpdateAlert) => {

    const { siteHierarchy, unaviableCellsForSite, cities } = useAppSelector((state) => state.general);
    const [cell, setCell] = useState<Cell | null>(null);
    const [closet, setCloset] = useState<Closet | null>(null);
    const [compound, setCompond] = useState<Compound | null>(null);
    const [city, setCity] = useState<ICity | null>(null);
    const { rcTranslate } = useTranslations();


    useEffect(() => {
        const currCity: ICity = cities?.find((city: ICity) => city.id === siteHierarchy?.cityId);
        const currCompond: Compound = siteHierarchy?.compounds?.find((comp: Compound) => comp.id === selectedData.compoundId);
        const currCloset: Closet | undefined = currCompond?.closets.find(c => c.id === selectedData.closetId);
        const currCell: Cell | undefined = currCloset?.cells.find(cell => cell.id == selectedData.cellId);
        setCity(currCity);
        setCompond(currCompond);
        setCloset(currCloset as Closet);
        setCell(currCell as Cell)
    }, [siteHierarchy])

    const getMessageStrings = (): Map<string, string> => {
        const strings: Map<string, string> = new Map<string, string>();
        switch (selectedData.type) {
            case DescendingHierarchyFormEnum.BLOCK:
                strings.set(MessageStringsEnum.TITLE, rcTranslate('lockers.updateLockersAlert.titleStartBlock'));
                strings.set(MessageStringsEnum.RELEASE_OR_BLOCK_MANY, rcTranslate('lockers.updateLockersAlert.block.willBlocks'));
                strings.set(MessageStringsEnum.RELEASE_OR_BLOCK_SINGLE, rcTranslate('lockers.updateLockersAlert.block.willBLock'));
                break;
            case DescendingHierarchyFormEnum.RELEASE:
                strings.set(MessageStringsEnum.TITLE, rcTranslate('lockers.updateLockersAlert.titleStartRelease'));
                strings.set(MessageStringsEnum.RELEASE_OR_BLOCK_MANY, rcTranslate('lockers.updateLockersAlert.release.willBeReleased'));
                strings.set(MessageStringsEnum.RELEASE_OR_BLOCK_SINGLE, rcTranslate('lockers.updateLockersAlert.release.willRelease'));
                break;
            case DescendingHierarchyFormEnum.UNPAIR:
                strings.set(MessageStringsEnum.TITLE, rcTranslate('lockers.updateLockersAlert.titleStartUnpair'));
                strings.set(MessageStringsEnum.RELEASE_OR_BLOCK_MANY, rcTranslate('lockers.updateLockersAlert.unpair.willUnpaires'));
                strings.set(MessageStringsEnum.RELEASE_OR_BLOCK_SINGLE, rcTranslate('lockers.updateLockersAlert.unpair.willUnpair'));
                break;
            default:
                break;
        }
        return strings;
    }

    const messageTitle = () => {
        const messageStrings: Map<string, string> = getMessageStrings();
        let title: string = messageStrings.get(MessageStringsEnum.TITLE) ?? "";
        const releaseOrBlockMany: string = messageStrings.get(MessageStringsEnum.RELEASE_OR_BLOCK_MANY) ?? "";;
        const releaseOrBlockSingle: string = messageStrings.get(MessageStringsEnum.RELEASE_OR_BLOCK_SINGLE) ?? "";
        if (!selectedData.compoundId) {
            title += ` ${rcTranslate('lockers.updateLockersAlert.inSite')} ${siteHierarchy.nameHE}  ${rcTranslate('lockers.updateLockersAlert.inCity')} ${city?.nameHE} ${releaseOrBlockMany}`;
        }
        else if (!selectedData.closetId) {
            title += ` ${rcTranslate('lockers.updateLockersAlert.inCompound')} ${compound?.nameHE} ${releaseOrBlockMany}`;
        }
        else if (!selectedData.cellId) {
            title += ` ${rcTranslate('lockers.updateLockersAlert.inCloset')} ${closet?.closetNumber} ${releaseOrBlockMany}`;
        }
        else if (selectedData.cellId) {
            title = `${rcTranslate('lockers.updateLockersAlert.inCell')} ${closet?.closetNumber}-${cell?.position} ${releaseOrBlockSingle}, ${rcTranslate('lockers.updateLockersAlert.continue')}`;
        }
        return (
            <div>{title}</div>
        );
    }

    const getLockersNumbersStrings = (): Map<string, string> => {
        const strings: Map<string, string> = new Map<string, string>();
        switch (selectedData.type) {
            case DescendingHierarchyFormEnum.BLOCK:
                strings.set(MessageStringsEnum.TOTAL_MATCH_CONDITION, rcTranslate('lockers.updateLockersAlert.block.numFree'));
                strings.set(MessageStringsEnum.TOTAL_WILL_BE_UPDATED, rcTranslate('lockers.updateLockersAlert.block.numWillBlock'));
                break;
            case DescendingHierarchyFormEnum.RELEASE:
                strings.set(MessageStringsEnum.TOTAL_MATCH_CONDITION, rcTranslate('lockers.updateLockersAlert.release.numBlocks'));
                strings.set(MessageStringsEnum.TOTAL_WILL_BE_UPDATED, rcTranslate('lockers.updateLockersAlert.release.numWillFree'));
                break;
            case DescendingHierarchyFormEnum.UNPAIR:
                strings.set(MessageStringsEnum.TOTAL_MATCH_CONDITION, rcTranslate('lockers.updateLockersAlert.unpair.numPairs'));
                strings.set(MessageStringsEnum.TOTAL_WILL_BE_UPDATED, rcTranslate('lockers.updateLockersAlert.unpair.numWillUnpair'));
                break;
            default:
                break;
        }
        return strings;
    }

    const lockersNumbers = () => {
        const lockersNumbersStrings = getLockersNumbersStrings();
        let numOfCells;
        if (!selectedData.compoundId) {
            numOfCells = countAviableCellsInSite();
        }
        else if (!selectedData.closetId) {
            const currComp = siteHierarchy?.compounds.find((comp: Compound)=>comp.id === selectedData.compoundId);
            numOfCells = countAviableCellsInCompound(currComp);
        }
        else if (!selectedData.cellId) {
            const currComp = siteHierarchy?.compounds?.find((comp: Compound)=>comp.id === selectedData.compoundId);
            const currCloset = currComp?.closets.find((closet: Closet)=>closet.id === selectedData.closetId);
            numOfCells = countAviableCellsInCloset(currCloset);
        }
        else {
            numOfCells = {totalMatchConditionNum: 0, totalWillBeUpdatedNum: 0}
        }
        return (
            <div>
                <div>{`${lockersNumbersStrings.get(MessageStringsEnum.TOTAL_MATCH_CONDITION)} ${numOfCells.totalMatchConditionNum}`}</div>
                <div>{`${lockersNumbersStrings.get(MessageStringsEnum.TOTAL_WILL_BE_UPDATED)} ${numOfCells.totalWillBeUpdatedNum}`}</div>
            </div>
        );
    }

    const countAviableCellsInSite = ()=> {
        let totalMatchConditionNum = 0;
        let totalWillBeUpdatedNum = 0;

        siteHierarchy?.compounds.forEach((compound: Compound) => {        
            const numOFcells = countAviableCellsInCompound(compound);
            totalMatchConditionNum = numOFcells.totalMatchConditionNum;
            totalWillBeUpdatedNum = numOFcells.totalWillBeUpdatedNum;
        })
        return {totalMatchConditionNum, totalWillBeUpdatedNum};
    }

    const countAviableCellsInCompound = (compound: Compound) => {
        let totalMatchConditionNum = 0;
        let totalWillBeUpdatedNum = 0;

        compound.closets.forEach((closet: Closet)=>{
            const numOFcells = countAviableCellsInCloset(closet);
            totalMatchConditionNum = numOFcells.totalMatchConditionNum;
            totalWillBeUpdatedNum = numOFcells.totalWillBeUpdatedNum;
        })
        return {totalMatchConditionNum, totalWillBeUpdatedNum};
    }

    const countAviableCellsInCloset = (closet: Closet) => {
        let totalMatchConditionNum = 0;
        let totalWillBeUpdatedNum = 0;

        switch (selectedData.type) {
            case DescendingHierarchyFormEnum.BLOCK:
                totalMatchConditionNum = closet.cells.filter(cell => 
                    !unaviableCellsForSite.includes(cell.id) && 
                    cell.cellStatus !== CellStatus.Blocked
                ).length;
                totalWillBeUpdatedNum = totalMatchConditionNum;
                break;

            case DescendingHierarchyFormEnum.RELEASE:
                totalMatchConditionNum = closet.cells.filter(cell => 
                    !unaviableCellsForSite.includes(cell.id) && 
                    cell.cellStatus === CellStatus.Blocked
                ).length;
                totalWillBeUpdatedNum = totalMatchConditionNum;
                break;

            case DescendingHierarchyFormEnum.UNPAIR:
                totalMatchConditionNum = closet.cells.filter(cell => 
                    cell.lockCaseId && cell.lockCaseId !== ""
                ).length;
                totalWillBeUpdatedNum = totalMatchConditionNum - closet.cells.filter(cell => 
                    !unaviableCellsForSite.includes(cell.id) &&
                    cell.lockCaseId && cell.lockCaseId !== ""
                ).length;
                break;

            default:
                break;
        }
        return {totalMatchConditionNum, totalWillBeUpdatedNum};
    }

    return (
        <div>
            {messageTitle()}
            {
                !selectedData.cellId &&
                <>
                {lockersNumbers()}
                {rcTranslate('lockers.updateLockersAlert.continue')}
                </>
            }
        </div>
    )
}

export default UpdateLockers;

enum MessageStringsEnum {
    TITLE = "Title",
    RELEASE_OR_BLOCK_MANY = "ReleaseOrBlockMany",
    RELEASE_OR_BLOCK_SINGLE = "ReleaseOrBlockSingle",
    IN_SITE = "InSite",
    TOTAL_MATCH_CONDITION = "TotalMatchConditon",
    TOTAL_WILL_BE_UPDATED= "TotalWillBeUpdated",
}