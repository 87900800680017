import { version } from "os";

export default {
  ERROR_DEFAULT: "אופס משהו השתבש!! {0}",
  sidebar: {
    customersManagement: {
      title: "לקוחות",
      children: {
        customersManagement: "ניהול לקוחות",
      },
    },
    roleManagement: {
      title: "תפקידים",
      children: {
        addRole: "הוספת תפקיד",
        roleManagement: "ניהול תפקידים",
      },
    },
    sitesManagement: {
      title: "אתרים",
      children: {
        compoundsManagement: "ניהול מתחמים",
        sitesManagement: "ניהול אתרים",
        closetsWithSiteManagement: "ניהול ארונות",
      },
    },
    ordersManagement: {
      title: "הזמנות",
    },
    usersManagement: {
      title: "משתמשים",
    },
    lockersManagement: {
      title: "לוקרים",
    },
    locksManagement: {
      title: "מנעולים",
    },
    configuration: {
      title: "הגדרות",
      children: {
        listManagement: "ניהול רשימות",
        cellManagement: "ניהול תבניות תאים",
        columnsManagement: "ניהול עמודות",
        citiesManagement: "ניהול ישובים",
        cabinetsManagement: "ניהול תבניות ארונות",
        fwVersionsManagement: "ניהול גירסאות קושחה",
      },
    },
    waitingListManagement: {
      title: "רשימות המתנה",
    },
    pricingPlansManagement: {
      title: "מחירונים",
      children: {
        pricingPlansList: "ניהול מחירונים",
      },
    },
    reportsManagement: {
      title: "דוחות",
      children: {
        ordersReports: "דוח הזמנות",
        sitesReports: "דוח אתרים וריכוז פעילות",
        siteDetailsReports: "דוח פרטי אתר",
      }
    },
  },
  search: {
    extendedSearch: "חיפוש מורחב",
    exportBtn: "ייצוא לאקסל",
  },
  header: {
    welcome: "שלום, {fullName}",
    lastLogin: "כניסתך האחרונה: ",
  },
  common: {
    back: "חזרה",
    clear: "נקה",
    search: "חיפוש",
    yes: "כן",
    no: "לא",
    all: "הכל",
  },
  customers: {
    filters: {
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      phoneNumber: "טלפון נייד",
      email: "כתובת דוא״ל",
      identity: "תעודת זהות",
      mailing: "דיוור",
      fromCreatedDate: "מתאריך",
      toCreatedDate: "עד תאריך",
      registrationDate: "תאריך רישום",
    },
    customerList: {
      actions: {
        customerDetails: "פרטי לקוח",
        orderManagement: "ניהול הזמנות",
        sendMessage: "שליחת הודעה",
      },
    },
    pageTitle: "ניהול לקוחות",
    customerDetails: {
      pageTitle: "פרטי לקוח",
      entityIdentifier: "מזהה לקוח: {id}",
      fieldNames: {
        firstName: "שם פרטי",
        lastName: "שם משפחה",
        idNum: "מספר ת.ז",
        phoneNumber: "טלפון נייד",
        email: "כתובת דוא״ל",
        mailSubscription: "קבלת דיוור",
        lastSavedDate: "נשמר בתאריך:",
        created: "תאריך הרשמה:",
      },
      subtitles: {
        general_settings: "הגדרות כלליות",
      },
      updateSuccess: "פרטי הלקוח עודכנו בהצלחה",
    },
  },
  users: {
    filters: {
      firstName: "שם פרטי",
      lastName: "שם משפחה",
      phoneNumber: "טלפון נייד",
      email: "כתובת דוא״ל",
      identity: "תעודת זהות",
      city: "ישוב",
      fromCreatedDate: "מתאריך",
      toCreatedDate: "עד תאריך",
      leadingRoleId: "תפקיד",
      registrationDate: "תאריך רישום",
    },
    manageUsers: {
      pageTitle: "ניהול משתמשים",
      rowActions: {
        reset_password: "שלח שוב קישור סיסמא ראשונית",
        update_user_permissions: "עדכון הרשאות אישיות",
        update_user_details: "עדכון פרטי משתמש",
      },
      addUser: "הוספת משתמש",
      resetPasswordSuccess: "הקישור נשלח בהצלחה",
    },
    userPermissions: {
      managePermissions: "ניהול הרשאות ל{userFullName}",
      updateSuccess: "ההרשאות עודכנו בהצלחה",
    },
    userDetails: {
      updateSuccess: "פרטי המשתמש עודכנו בהצלחה",
      addSuccess: "המשתמש נוסף בהצלחה",
      linkSitesError:
        "כאשר בחרת באופציה של שיוך אתרים, עליך לשייך לפחות אתר אחד.",
      fieldNames: {
        isActive: "משתמש פעיל",
        firstName: "שם פרטי",
        lastName: "שם משפחה",
        phoneNumber: "טלפון נייד",
        email: "כתובת דוא״ל",
        identificationNumber: "תעודת זהות",
        cityId: "ישוב",
        leadingRoleId: "תפקיד",
        userSites: {
          label: "שיוך לאתרים",
          allSites: "כל האתרים",
          linkSites: "שיוך אתרים",
        },
        multiSelect: "בחירה מרובה",
      },
      subtitles: {
        general_settings: "הגדרות כלליות",
      },
      pageTitle: {
        update: "עדכון פרטי משתמש",
        add: "הוספת משתמש",
      },
      entityIdentifier: "מזהה משתמש: {id}",
    },
  },
  orders: {
    filters: {
      orderNumber: "מס' הזמנה",
      orderDate: "תאריך הזמנה",
      city: "ישוב",
      site: "אתר",
      startDate: "ת.התחלה",
      endDate: "ת.סיום",
      orderFee: "דמי רישום",
      totalPrice: "סה״כ שולם",
      orderStatus: "סטטוס הזמנה",
      firstAllocationDate: "ת.הקצאה",
      payer: "משלם",
      user: "משתמש",
      subscriptionModel: "סוג הזמנה",
      pricingPeriod: "יח' חיוב",
      pricingInitialPrice: "מחיר פתיחה",
      pricingUnitPrice: "מחיר ליח' חיוב",
      isInitiatorOriginatedOrder: "סוג הזמנה",
      initiatorPhoneNumber: "מספר טלפון מוסר החבילה"
    },
    manageOrders: {
      pageTitle: "ניהול הזמנות",
      inactiveOrder: "ההזמנה לא פעילה",
      rowActions: {
        show_invoice: "צפייה בחשבונית",
        order_history: "היסטוריית הזמנה",
        order_messages_history: "היסטוריית הודעות",
        locker_release: "שחרור לוקר",
        locker_details: "פרטי לוקר",
        update_locker_fee: "עדכון דמי מנעול",
        order_cancellation: "ביטול הזמנה",
        choose_locker: "בחר לוקר",
      },
      extendedContent: {
        locker_assignment: "שיוך נוכחי ללוקר",
        payment_details: "פרטי תשלום",
        payer_details: "לקוח משלם",
        user_details: "לקוח משתמש",
        payer_and_user_details: "לקוח משלם ומשתמש",
        comments: "הערות",
        locker_number: "מספר לוקר:",
        nickname: "כינוי:",
        payment_method: "אופן תשלום:",
        payment_status: "סטטוס תשלום:",
        order_registration_platform: "רישום",
        price: "מחיר:",
        lock_fee: "דמי רישום:",
        token_number: "מספר טוקן:",
        discount: "הנחה:",
        discount_reason: "סיבת הנחה:",
        more_details: "לפרטים נוספים",
        paymentProviderTransactionId: "אסמכתא",
      },
      orderStatus: {
        text: "מצב הזמנה",
        active: "הזמנה פעילה",
        inactive: "הזמנה לא פעילה",
        future: "הזמנה עתידית",
        canceled: "הזמנה מבוטלת",
      },
      orderType: {
        delivery: "מסירת משלוח",
        originatedOrder: "הזמנה רגילה"
      }
    },
    orderDetails: {
      updateSuccess: "פרטי ההזמנה עודכנו בהצלחה",
      fieldNames: {
        isActive: "משתמש פעיל",
        firstName: "שם פרטי",
        lastName: "שם משפחה",
        phoneNumber: "טלפון נייד",
        email: "כתובת דוא״ל",
        identificationNumber: "תעודת זהות",
        cityId: "ישוב",
        leadingRoleId: "תפקיד",
        userSites: {
          label: "שיוך לאתרים",
          allSites: "כל האתרים",
          linkSites: "שיוך אתרים",
        },
        multiSelect: "בחירה מרובה",
      },
      subtitles: {
        general_settings: "הגדרות כלליות",
      },
      pageTitle: "עדכון פרטי הזמנה",
    },
    orderHistory: {
      pageTitle: "היסטוריית הזמנה {orderId}",
      orderDate: "תאריך הזמנה",
      created: "תאריך פעולה",
      createdBy: "בוצע על ידי",
      notes: "הערות",
      cellNumber: "מספר לוקר",
      userFullName: "בוצע על ידי",
      userType: "סוג משתמש",
      orderOperationType: "סוג פעולה",
    },
    orderMessagesHistory: {
      pageTitle: "היסטורית הודעות להזמנה {orderId}",
      content: "תוכן ההודעה",
      createdByFullName: "שולח",
      created: "תאריך",
      isSuccessfulDelivery: "האם נשלח בהצלחה?",
      deliveryFailureMessage: "סיבת הכישלון",
      yes: "כן",
      no: "לא",
      orderDate: "תאריך הזמנה",

    },
    lockerRelease: {
      title: "שחרור לוקר",
      subTitle: "האם לשחרר לוקר {lockerNumber}?",
      city: "ישוב: {cityName}",
      site: "אתר: {siteName}",
      compound: "מתחם: {compoundName}",
      reason: "סיבה",
      success: "שחרור הלוקר בוצע בהצלחה",
      required: "הזן סיבה",
    },
    orderCancellation: {
      title: "ביטול הזמנה",
      alert: "לאחר הפעולה הלקוח ישאר ללא לוקר",
      subTitle: "האם לבטל הזמנה {orderId}?",
      reason: "סיבה",
      success: "ביטול ההזמנה בוצע בהצלחה",
    },
    lockerReleaseConfirm: {
      title: "שחרור לוקר",
      subTitle: "האם  אתה בטוח?",
      reason: "סיבה",
      success: "ביטול ההזמנה בוצע בהצלחה",
    },
    sendMessageToOrders: {
      characters: "תווים",
      title: "שליחת הודעה להזמנות",
      confirmTitle: "אישור משלוח הודעות",
      textInputLabel: "הזן הודעה לשליחה",
      lengthLimit: "ניתן להזין עד 256 תווים",
      for: "ל",
      customers: "לקוחות",
      subTitle: "נא לאשר שליחת הודעה:",
    }
  },
  sites: {
    filters: {
      id: "קוד אתר",
      openForOrders: {
        label: "פתוח להזמנות",
        yes: "כן",
        no: "לא",
      },
      isActive: {
        label: "אתר פעיל",
        yes: "כן",
        no: "לא",
      },
      name: "שם אתר",
      siteTypeId: "סוג אתר",
      subSiteTypeId: "תת אתר",
      primaryPhoneNumber: "טלפון ראשי",
      lockerPrice: "מחיר",
      lockFee: "דמי שימוש למנעול",
      cityId: "ישוב",
      rentalRouteId: "מסלול",
    },
    manageSites: {
      pageTitle: "ניהול אתרים",
      rowActions: {
        site_details: "פרטי אתר",
        manage_lockers: "ניהול ארונות",
        manage_sections: "ניהול מתחמים",
        manage_orders: "ניהול הזמנות",
        contancts_and_agents: "אנשי קשר וסוכנים",
        orders_statuses: "סטטוס הזמנות",
        open_close_to_orders: "פתיחת/סגירת אתר להזמנות",
        site_pricing_plans: "מחירונים לאתר",
      },
      addSite: "הוספת אתר",
      dialog: {
        subTitle: "עדכון סטטוס הזמנות לאתר {siteName}",
        orderStatus: {
          label: "סטטוס",
          yes: "פתוח להזמנות",
          no: "סגור להזמנות",
        },
        sendNotification: "שליחת הודעות",
        success: "עדכון סטטוס הזמנות בוצע בהצלחה",
      },
      sitePricingPlansDialog: {
        title: "מחירונים לאתר {siteName}",
        ppp: "מסלול PPP",
        ppu: "מסלול PPU",
        updateSuccess: "העדכון בוצע בהצלחה",
      },
    },
    siteDetails: {
      updateSuccess: "פרטי האתר עודכנו בהצלחה",
      fieldNames: {
        active: "מוצג באתר הזמנות",
        nameHE: "שם אתר בעברית",
        nameEN: "שם אתר באנגלית",
        site_type: "סוג אתר",
        sub_site: "תת אתר",
        primary_phone_number: "טלפון ראשי",
        potential_customers_count: "מספר תלמידים",
        fax: "פקס",
        locker_price: "מחיר לוקר באתר",
        lock_fee: "דמי שימוש למנעול",
        city: "ישוב",
        address: "כתובת",
        postal_code: "מיקוד",
        rent_courses: "מסלולי השכרה",
        note: "הערה",
        agreement_details: "פרטי הסכם",
        commission: "עמלה",
        commissionNote: "הערת עמלה",
        agreement_period: "תקופת הסכם",
        agreementPeriodDateFrom: "מתאריך",
        agreementPeriodDateTo: "עד תאריך",
        agreementPeriodNote: "הערת תקופת הסכם",
        rentalRoutes: "מסלולי השכרה",
        district: "מחוז",
        price_list: "מחירונים",
        PayPerPeriodPricingPlanId: "מסלול PPP",
        PayPerUsePricingPlanId: "מסלול PPU",
        allowPPU: "אפשר הזמנות PPU",
      },
      additionalDetails: {
        created: "תאריך הקמה",
        code: "קוד אתר",
        open_for_orders: "פתוח להזמנות",
      },
      pageTitle: {
        add: "הוספת אתר",
        update: "עדכון פרטי אתר",
      },
      entityIdentifier: "קוד אתר: {id}",
    },
    manageCompounds: {
      addCompound: "הוספת מתחם",
      pageTitle: "ניהול מתחמים לאתר {siteName}",
      fields: {
        compoundName: "שם מתחם",
        description: "תיאור מתחתם",
        isActive: "פעיל",
        isLockedList: "חסימת רשימת המתנה",
      },
      actions: {
        classMapping: "מיפוי כיתות",
        delete_compound: "מחיקת מתחם",
      },
      dialog: {
        title: "מחיקת מתחם",
        body: "האם אתם בטוחים שברצונכם למחוק את המתחם?",
      },
      updateSuccess: "פרטי המתחם עודכנו בהצלחה",
      addSuccess: "המתחם נוסף בהצלחה",
      updateGradesSuccess: "כיתות המתחם עודכנו בהצלחה",
      deleteCompoundSuccess: "המתחם נמחק בהצלחה",
      dialogTitle: "מיפוי כיתות למתחם",
      autoCompleteLabel: "בחירת כיתות",
      autoCompleteError: "חובה לבחור לפחות כיתה אחת",
      alreadyTakenCompoundNameError: "שם המתחם כבר קיים באתר",
    },
    manageClosetsWithSite: {
      pageTitle: "ניהול ארונות",
      pageTitleWithSite: "ניהול ארונות של אתר {siteName}",
      addCloset: "הוספת ארון",
      mobility: "ניוד",
      printClosetCellsQR: "הדפסת QR",
      printBarcode: "הדפסת ברקוד",
      sureToUpdateClosetMobility:
        "קיימות הזמנות מקושרות לארון זה. האם לבצע ניוד ארון לאתר {siteName} מתחם {compoundName}",
      printQRCodesAlso: "הדפסת QR של הלוקרים המשוייכים לארונות שנבחרו",
      mobilyCloset: "ניוד ארון {closetNumber}",
      updateClosetMobilitySuccess: "ניוד הארון עודכן בהצלחה",
      rowActions: {
        closet_details: "פרטי ארון",
        manage_lockers: "ניהול לוקרים",
        manage_orders: "ניהול הזמנות",
        closet_mobility: "ניוד ארון",
      },
      filters: {
        id: "מספר ארון",
        name: "שם תצוגה",
        closetId: "מספר מזהה",
        closetTypeId: "סוג תבנית",
        manufacturer: "יצרן",
        color: "צבע",
        created: "שנת ייצור",
        position: "מיקום",
        typeDoorCladding: "סוג חיפוי לדלת",
        outdoorEnvironments: {
          label: "סביבות חוץ",
          yes: "כן",
          no: "לא",
        },
        extraRoof: {
          label: "תוספת גגון",
          yes: "כן",
          no: "לא",
        },
        cityId: "ישוב",
        siteId: "אתר",
        compoundId: "מתחם",
      },
      mobilyClosetConfirmationModal: {
        title: "הזמנות פעילות/עתידיות",
        body: "שים לב כי בארון יש לוקרים עם הזמנות פעילות/עתידיות",
      },
    },
    closetWithSiteDetails: {
      pageTitle: {
        update: "עדכון פרטי ארון",
        add: "הוספת ארון",
      },
      entityIdentifier: "מספר מזהה: {id}",
      site_settings: "הגדרות אתר",
      closetDisplay: "לצפייה בתצוגת ארון",
      addSuccess: "הארון נוסף בהצלחה",
      updateSuccess: "הארון עודכן בהצלחה",
      alreadyTakenCityNamClosetNumberError: "מספר הארון כבר קיים",
      dialog: {
        title: "תצוגת פרטי ארון",
        confirmText: "סגירה",
      },
    },
  },
  searchFields: {
    customerId: "מזהה לקוח",
    userId: "מזהה משתמש",
    identity: "תעודת זהות",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    idNum: "מספר ת.ז",
    phoneNumber: "טלפון נייד",
    email: "כתובת דוא״ל",
    mailing: {
      label: "קבלת דיוור",
      items: {
        yes: "כן",
        no: "לא",
      },
    },
    openForOrders: {
      label: "פתוח להזמנות",
      items: {
        yes: "כן",
        no: "לא",
      },
    },
    isActive: {
      label: "סטטוס",
      items: {
        active: "פעיל",
        inactive: "לא פעיל",
      },
    },
    fromCreatedDate: "מתאריך",
    toCreatedDate: {
      label: "עד תאריך",
      helperText: "בשדה יוצג התאריך של היום הבא בכוונה",
    },
    paymentStatus: {
      label: "סטטוס תשלום",
    },
    subscriptionModel: {
      label: "סוג הזמנה",
      items: {
        ppp: "מסלול PPP",
        ppu: "מסלול PPU",
      },
    },
    isInitiatorOriginatedOrder: {
      label: "מסירת חבילה",
    },
    initiatorPhoneNumber: {
      label: "מספר מוסר החבילה"
    },
    site: "אתר",
    area: "מתחם",
    locker_number: "מספר לוקר",
    locker_id: "מספר מזהה לוקר",
    orderNumber: "מספר הזמנה",
    lockerNumber: "מספר ארון",
    tokenNumber: "מספר טוקן",
    dateRange: {
      label: "טווח תאריכים",
      firstAllocationDate: "תאריך הקצאה ראשונה",
      expirationDate: "תאריך תוקף",
      created: "תאריך הזמנה",
    },
    registration: {
      label: "רישום",
      website: "אתר",
      app: "אפליקציה",
      migration: "מיגרציה",
    },
    orderStatus: "סטטוס הזמנה",
    city: "ישוב",
    role: "תפקיד",
    extendedSearch: "חיפוש מורחב",
    closeExtendedSearch: "סגירת חיפוש מורחב",
    cleanSearch: "ניקוי",
  },
  login: {
    fieldNames: {
      idNumLabel: "תעודת זהות",
      passwordLabel: "סיסמא",
    },
    title: "התחברות",
    submitButton: "כניסה",
    forgotPassword: "שכחתי סיסמא",
  },
  firstLogin: {
    fieldNames: {
      idNumLabel: "תעודת זהות",
      phoneNumberLabel: "טלפון נייד",
      password: "סיסמא",
      confirmPassword: "סיסמא חוזרת",
    },
    title: "כניסה ראשונה למערכת",
    button: "כניסה למערכת",
    subTitle: "אנא הזינו את הפרטים הבאים:",
    success: "הרישום הסתיים בהצלחה",
    passwordInstructions:
      "יש להזין סיסמא בעלת לפחות 8 תווים, המשלבים אות גדולה ואות קטנה באנגלית, ספרות ותווים מיוחדים",
    submitButton: "כניסה",
    cancelButton: "ביטול",
  },
  forgotPassword: {
    title: "שכחתי סיסמא",
    subTitle:
      "יש להזין תעודת זהות  ומיד יישלח לטלפון שלך הנחיות להגדרת סיסמא חדשה",
    success:
      "בקשתך התקבלה בהצלחה, ברגעים הקרובים ישלח אליך הודעה ב-SMS עם הנחיות להחלפת סיסמא",
    fieldNames: {
      idNum: "תעודת זהות",
    },
  },
  changePassword: {
    fieldNames: {
      newPassword: "סיסמא חדשה",
      confirmPAssword: "סיסמא חוזרת",
      idNum: "תעודת זהות",
      phoneNumberLabel: "טלפון נייד",
    },
    title: "החלפת סיסמא",
    subTitle: "הסבר על החלפת סיסמא",
    success: "הסיסמא הוחלפה בהצלחה!",
    login: "כניסה למערכת",
  },
  changeExpiredPassword: {
    title: "החלפת סיסמא",
    subTitle: "אנא הזינו את הפרטים הבאים:",
    success: "הסיסמא הוחלפה בהצלחה!",
    fieldNames: {
      idNum: "תעודת זהות",
      oldPassword: "סיסמא נוכחית",
      newPassword: "סיסמא חדשה",
      confirmPassword: "הזנה חוזרת",
    },
    passwordInstructions:
      "יש להזין סיסמא בעלת לפחות 8 תווים, המשלבים אות גדולה ואות קטנה באנגלית, ספרות ותווים מיוחדים",
    login: "כניסה למערכת",
  },
  button: {
    confirm: "אישור",
    cancel: "ביטול",
    reset: "איפוס",
    search: "חיפוש",
    save: "שמירה",
    add: "הוספה",
    login: "כניסה",
    exportCSV: "ייצוא לאקסל",
    exportPDF: "ייצוא ל-PDF",
    allocate: "שיבוץ לוקר",
    send: "שליחה",
  },
  validation: {
    passwordError: "הסיסמא לא תקינה",
    idNumError: "מספר הזהות לא תקין",
    requiredError: "שדה חובה",
    numbersError: "יש להזין מספרים בלבד",
  },
  table: {
    totalRows: "סה״כ רשומות: ",
    noResults: "לא נמצאו תוצאות",
  },
  roleManagement: {
    roleForm: {
      roleDescription: "תיאור תפקיד",
      manage_permissions: "ניהול הרשאות",
      addRole: "הוספת תפקיד",
      editRole: "עריכת תפקיד",
      addSuccess: "התפקיד נוסף בהצלחה",
      editSuccess: "התפקיד עודכן בהצלחה",
    },
    rolesTable: {
      name: "תיאור תפקיד",
      created: "תאריך הקמה",
      roleId: "מזהה תפקיד",
      title: "ניהול תפקידים",
      addRole: "הוספת תפקיד",
      rowActions: {
        edit_role: "עריכת תפקיד",
        delete_role: "מחיקת תפקיד",
      },
      dialog: {
        title: "מחיקת תפקיד",
        body: "האם אתם בטוחים שברצונכם למחוק את התפקיד?",
      },
      alert: {
        message: "התפקיד נמחק בהצלחה",
      },
    },
  },
  legend: {
    legendTitle: "מקרא:",
    legendItems: {
      disabledCheckbox: "הרשאה שקיימת כבר במסגרת התפקיד של המשתמש",
      activeCheckbox: "הרשאה אישית שניתנת לבחירה",
    },
  },
  dialog: {
    confirmTitle: "אישור",
    closeTitle: "ביטול",
  },
  backButton: {
    title: "חזרה",
  },
  configuration: {
    title: "קונפיגורציה",
    commonFieldNames: {
      new: "תבנית חדשה:",
      entityIdentifier: "מספר מזהה",
      title: "תיאור עמודה",
      height: 'גובה בס"מ:',
      width: 'רוחב בס"מ:',
      depth: "עומק",
      isActive: "פעיל",
      cellSize: "גודל תא:",
      addColumn: "הוספת עמודה",
      saveColumn: "שמירת עמודה",
      addCell: "הוספת תא",
      saveCell: "שמירת תא",
      resetCells: "מחיקת כל התאים",
    },
    cellConfiguration: {
      pageTitle: "ניהול תבניות תאים",
      addSuccess: "נשמר בהצלחה",
      editSuccess: "נשמר בהצלחה",
      alreadyTakenCellSizeError: "גודל התא קיים בתבנית אחרת",
      alreadyTakenCellNameError: "שם התא קיים בתבנית אחרת",
      fieldNames: {
        title: "תיאור תבנית תא",
      },
      addCell: "הוספה",
    },
    fwVersionsConfiguration: {
      pageTitle: "ניהול גרסאות קושחה",
      addSuccess: "נשמר בהצלחה",
      editSuccess: "נשמר בהצלחה",
      addNewVersion: "הוספה",
      alreadyTakenTitleError: "תיאור הגרסה כבר קיים",
      alreadyTakenVersioAndPemError: "גרסה וסוג PEM כבר קיימים",
      fileUploadError: "קובץ לא תקין",
      fieldNames: {
        new: "גרסה חדשה:",
        id: "מזהה גרסה",
        title: "תיאור גרסה",
        version: "גרסה",
        pemList: "מספר PEM",
        uploadDate: "תאריך העלאה",
        uploadFile: "העלאת קובץ",
        downloadFile: "הורדת קובץ",
        fileName: "שם הקובץ",
      },
    },
    columnConfiguration: {
      pageTitle: "ניהול עמודות",
      addSuccess: "העמודה נוספה בהצלחה",
      editSuccess: "העמודה עודכנה בהצלחה",
      fieldNames: {
        depth: 'עומק בס"מ',
        cellFormationsCount: 'סה"כ עמודות',
        actions: {
          update_cell_details: "פירוט תאים",
        },
      },
      children: {
        pageTitle:
          "פרטי תאים לעמודה  {columnName}: גובה: {columnHeight}, רוחב: {columnWidth}, עומק: {columnDepth} ",
        updateSuccess: "פרטי התאים עודכנו בהצלחה",
        errors: {
          totalHeights: "סך גובה התאים שנבחר חייב להיות זהה לגובה העמודה",
          cellHeight: "גובה התאים לא תואם לגובה העמודה",
        },
      },
    },
    cityConfiguration: {
      pageTitle: "ניהול ישובים",
      addSuccess: "הישוב נוסף בהצלחה",
      editSuccess: "הישוב עודכן בהצלחה",
      alreadyTakenCityNameError: "שם הישוב כבר קיים",
      addCity: "הוספת ישוב",
      fieldNames: {
        new: "ישוב חדש:",
        entityIdentifier: "מספר מזהה",
        nameHE: "שם ישוב בעברית",
        nameEN: "שם ישוב באנגלית",
        district: "מחוז",
        isActive: "פעיל",
        isPopular: "פופולרי",
      },
      sortCities: "מיון ישובים",
    },
    listManagement: {
      title: "ניהול רשימות",
      listName: "שם רשימה",
      entityIdentifier: "מספר מזהה: {id}",
      linkViewList: "לצפייה ברשימה",
      listOptions: {
        title: "רשימת {nameList}",
        entityIdentifier: "מספר מזהה",
        hebDesc: "תיאור בעברית",
        engDesc: "תיאור באנגלית",
        isActive: "פעיל",
      },
      updateSuccess: "הרשימה עודכנה בהצלחה",
    },
    closetConfiguration: {
      pageTitle: "ניהול תבניות ארונות",
      subTitle: "*גובה מקסימלי בתבנית בארון",
      addCloset: "הוספה",
      addSuccess: "נשמר בהצלחה",
      editSuccess: "נשמר בהצלחה",
      editDetailsSuccess: "פרטי תבנית ארון עודכנו בהצלחה",
      alreadyTakenClosetNameError: "שם הארון כבר קיים",
      fieldNames: {
        new: "תבנית חדשה:",
        entityIdentifier: "מספר מזהה",
        title: "תיאור תבנית ארון",
        height: "גובה מקסימלי:",
        width: "רוחב:",
        depth: "עומק:",
        totalColumns: "עמודות:",
        totalCells: "תאים:",
        actions: {
          closetDetails: "פרטי תבנית ארון",
          updateLocation: "עדכון מיקום",
        },
      },
      children: {
        pageTitle: "פרטי תבנית ארון {closetName}",
        
        title: "רשימת עמודות",
        column: "עמודה",
        totalCell: 'סה"כ תאים: ',
        save: "שמירת פרטי תבנית ארון",
        updateSuccess: "פרטי תבנית ארון עודכנו בהצלחה",
        exceedingWidth: "חריגה מרוחב הארון",
        exceedingHeight: "חריגה מגובה הארון",
        exceedingHeightAndWidth: "חריגה מגובה הארון",
        requiredError: "יש להזין מיקום ותבנית לכל תא",
        alreadyTakenPositionError: "מיקום התא כבר קיים בארון",
        equalHeightAndWidth:
          "סך כל גובה ורוחב העמודות חייב להיות זהה לגובה ורוחב הארון",
        equalSetHeight:
          "יש להזין לפחות עמודה אחת אשר הגובה שלה זהה לגובה המקסימלי שהוגדר לארון",
        deleteColumn: "מחיקת עמודה",
        fieldNames: {
          title: "תבנית תא",
          position: "מיקום",
          totalCells: "כמות תאים",
        },
      },
    },
  },
  lockers: {
    manageLockers: {
      pageTitle: "ניהול לוקרים",
      description: "תיאור",
      sendMessage: "שליחת הודעה",
      oderManagement: "ניהול הזמנות",
      changeLock: "החלפת מנעול",
      migrationOrdersHandle: "ניהול הזמנות מיגרציה",
      cell: "תבנית תא",
      reason: "סיבה",
      cellSize: "גודל תא",
      filters: {
        siteId: "אתר",
        cityId: "ישוב",
        compoundId: "מתחם",
        cellNumber: "מספר לוקר",
        cellId: "מספר מזהה",
        cellNickName: "כינוי",
        closetNumber: "ארון",
        cellStatus: "סטטוס",
        cellFormationId: "תבנית תא",
        isAccessible: {
          label: "נגישות",
          yes: "כן",
          no: "לא",
        },
        lockCaseId: "מספר מנעול",
        pairingDate: "ת. ברקוד אחרון",
        fromPairingDate: "תאריך ברקוד",
        toPairingDate: "עד תאריך",
      },
    },
    lockerAllocate: {
      title: "שיבוץ לוקר",
    },
    blockLockers: {
      title: "חסימת לוקרים",
    },
    releaseLockers: {
      title: "שחרור חסימת לוקרים",
    },
    unpairLockersFromCells: {
      title: "שחרור מנעולים מלוקרים",
    },
    updateLockersAlert: {
      titleStartRelease: "כל הלוקרים החסומים",
      titleStartBlock: "כל הלוקרים הפנויים",
      titleStartUnpair: "פעולה זו תשחרר את המנעולים מכל הלוקרים",
      inCity: "בעיר",
      inSite: "באתר",
      inCompound: "במתחם",
      inCloset: "בארון מס'",
      inCell: "לוקר מספר",
  
      continue: "האם להמשיך בפעולה?",
      block: {
        willBLock: "ייחסם",
        willBlocks: "ייחסמו",
        numFree: "מספר הלוקרים הפנויים:",
        numWillBlock: "מספר הלוקרים שייחסמו:",
      },
      release: {
        willRelease: "ישוחרר",
        willBeReleased: "ישוחררו",
        numBlocks: "מספר הלוקרים החסומים:",
        numWillFree: "מספר הלוקרים שישוחררו:",
      },
      unpair: {
        willUnpair: "ישוחרר ממנעולו",
        willUnpaires: " ",
        numPairs: "מספר המנעולים המשוייכים ללוקרים:",
        numWillUnpair: "מספר המנעולים שישוחררו מהצימוד ללוקרים:",
      },
    },
    blockReport: {
      title: 'דו"ח חסימת לוקרים',
      succededTitle: "לוקרים שנחסמו",
      failedTitle: "לוקרים שנכשלו",
      noSuccess: "אין לוקרים שנחסמו",
      noFailed: "אין לוקרים שנכשלו",
    },
  },
  dialogUpdateStatus: {
    items: {
      active: "פעיל",
      notActive: "לא פעיל",
    },
    updateStatus: "עדכון סטטוס",
    updateSuccess: "הסטטוס עודכן בהצלחה",
    status: "סטטוס",
    updateItemsStatusNote:
      "לתשומת ליבך, הפעולה תבוצע ל {sumItems}  רשומות שסומנו במסך",
    updateStatusNote: "לתשומת ליבך, הפעולה תבוצע לרשומה שסומנה במסך",
    note: "עליך לבחור לפחות רשומה אחת",
  },
  dialogBlockLocker: {
    blockLocker: "חסימת לוקר",
  },
  dialogReleaseLocker: {
    releaseLocker: "שחרור לוקר חסום",
  },
  dialogUnpairLockersFromCells: {
    unpairLockersFromCells: "שחרור מנעול מלוקר",
  },
  locks: {
    filters: {
      caseId: "מספר קייסינג",
      macAddress: "Mac Address",
      lockStatus: "סטטוס",
      pemType: "Pem Type",
      ecId: "EC ID",
      electronicUnitNumber: "מס' ייצור יחידה אלקטרונית",
      firmwareVersion: "Firmware version",
      dateRangeBy: {
        label: "טווח תאריכים",
        pairingDate: "תאריך צימוד",
        createdDate: "תאריך יצירה",
        statusUpdateDate: "תאריך עדכון סטטוס",
      },
      fromDate: "מתאריך",
      toDate: "עד תאריך",
      pemVersion: "PEM Version",
      lastDatePairing: "תאריך צימוד אחרון",
      statusUpdateDate: "תאריך עדכון סטטוס",
      isResetRequired: "נידרש איפוס",
    },
    releaseLockMigrationPage: {
      pageTitle: "שחרור מנעול מיגרציה",
      form: {
        fields: {
          caseId: "מספר מנעול",
          id: "מספר תעודת זהות",
        },
        identificationTooLong: "תעודת הזהות ארוכה לא יכולה להכיל יותר מ9 ספרות",
        submitButton: "שחרר מנעול",
      },
      successAlert: "המנעול שוחרר בהצלחה",
    },
    addLock: {
      pageTitle: "רישום מנעול",
      form: {
        fields: {
          caseId: "מספר מנעול",
          macAddress: "כתובת מנעול",
          pemType: "סוג יחידה",
          electronicUnitNumber: "מספר יחידה אלקטרוני",
          firmwareVersion: "גרסת חומרה",
        },
        submitButton: "רישום מנעול",
      },
      successAlert: "המנעול נרשם בהצלחה",
    },
    locksManagement: {
      pageTitle: "ניהול מנעולים",
      releaseMigrationLockButton: "שחרור מנעול מיגרציה",
      addLockButton: "רישום מנעול",
      rowActions: {
        action: "פעולה",
      },
      action: "פעולה",
    },
  },
  waitingLists: {
    manageWaitingLists: {
      pageTitle: "ניהול רשימות המתנה",
      filters: {
        waitingListType: "סוג המתנה",
        site: "אתר",
        compound: "מתחם",
        waitingListId: "מספר מזהה",
        firstName: "שם פרטי",
        lastName: "שם משפחה",
        identity: "תעודת זהות",
        phoneNumber: "מספר טלפון",
        email: "מייל",
        status: "סטטוס",
        statusUpdateDate: "תאריך עדכון ססטוס",
        created: "תאריך יצירה",
        dateRangeBy: {
          label: "טווח תאריכים",
          statusUpdateDate: "תאריך עדכון סטטוס",
          createdDate: "תאריך יצירה",
        },
        fromDate: "מתאריך",
        toDate: "עד תאריך",
      },
      siteOpenModal: {
        city: "בחירת יישוב",
        site: "בחירת אתר",
      },
      siteOpeningMessage: "הודעת פתיחת אתר",
      messageToCompound: "הודעה למתחם",
      siteOpeningModalTitle: "שליחת הודעה שאתר נפתח להזמנות",
      siteIsOpenSuccess: "הודעת פתיחת אתר נשלחה בהצלחה",
    },
  },
  pricingPlans: {
    pricingPlansManagment: {
      pageTitle: "ניהול מחירונים",
      add: "הוספת מחירון",
      update: "עדכון מחירון",
      delete: "מחיקת מחירון",
      deleteSuccess: "המחירון נמחק בהצלחה",
      fieldNames: {
        id: "מספר מזהה:",
        title: "שם מחירון:",
        subscriptionModel: "מסלול:",
        pricingPeriod: "יחידת חיוב:",
        created: "תאריך יצירה:",
      },
    },
    pricingPlanDetails: {
      pageTitle: {
        add: "הוספת מחירון",
        update: "עדכון מחירון",
      },
      addSuccess: "המחירון נוסף בהצלחה",
      updateSuccess: "המחירון עודכן בהצלחה",
      fieldNames: {
        title: "שם מחירון",
        pricingPeriod: "יחידת חיוב",
        subscriptionModel: "מסלול",
        pricing: "הגדר מחירים לכל גודל לוקר",
        cellSize: "גודל תא: {cellSize}",
        initialPrice: "מחיר פתיחה",
        unitPrice: "מחיר ליחידת חיוב",
        marketingName: "שם שיווקי",
      },
    },
  },
  reports: {
    ordersReport: {
      pageTitle: "דוח הזמנות"
    },
    ordersBySitesReport: {
      pageTitle: "דוח הזמנות לפי אתרים ריכוז פעילות"
    },
    siteDetailsReport: {
      pageTitle: "דוח פרטי אתר"
    },
  },
  
  descendingHierarchyForm: {
    city: {
      label: "עיר",
    },
    site: {
      label: "אתר",
    },
    compound: {
      label: "מתחם",
    },
    closet: {
      label: "ארון",
    },
    cell: {
      label: "לוקר",
    },
    resson: {
      label: "*סיבה",
    },
    allocate: {
      noPpp:"בחירת הלוקר אינה מאושרת. באתר זה ניתן לבחור לוקר רק בהזמנות במסלול לפי שימוש. לבחירת לוקר באתר זה, יש לבצע הזמנה חדשה."
    },
  },
  errors: {
    errorCode: "קוד שגיאה",
    generalError: "שגיאה כללית",
  },

  yes: "כן",
  no: "לא",
};
